import { Component, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  title = 'CONFIRM';
  message = 'CONFIRM ?';
  btnConfirm = 'CONFIRM';
  generateWebexLink = false;
  bsModalRef: BsModalRef;
  class = 'modal-md';
  confirm: () => void;
  @ViewChild('template', { static: false }) template: TemplateRef<any>;
  @Output() hideEvent = new EventEmitter<boolean>();

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit() { }

  openModal(): any {
    this.bsModalRef = this.modalService.show(this.template, { class: this.class, ignoreBackdropClick: true });
  }

  onSubmit(): void {
    if (this.confirm) { this.confirm(); }
  }

  onClose(): void {
    this.bsModalRef.hide();
    this.hideEvent.emit(this.generateWebexLink);
  }

}
