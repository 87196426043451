/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-paginator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "./custom-paginator.component";
var styles_CustomPaginatorComponent = [i0.styles];
var RenderType_CustomPaginatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomPaginatorComponent, data: {} });
export { RenderType_CustomPaginatorComponent as RenderType_CustomPaginatorComponent };
function View_CustomPaginatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Prev"]))], null, null); }
function View_CustomPaginatorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Next"]))], null, null); }
function View_CustomPaginatorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["First"]))], null, null); }
function View_CustomPaginatorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Last"]))], null, null); }
function View_CustomPaginatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "ngb-pagination", [["role", "navigation"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = (_co.onPageChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbPagination_0, i2.RenderType_NgbPagination)), i1.ɵdid(1, 573440, null, 6, i3.NgbPagination, [i3.NgbPaginationConfig], { boundaryLinks: [0, "boundaryLinks"], rotate: [1, "rotate"], collectionSize: [2, "collectionSize"], maxSize: [3, "maxSize"], page: [4, "page"], pageSize: [5, "pageSize"] }, { pageChange: "pageChange" }), i1.ɵqud(603979776, 1, { tplEllipsis: 0 }), i1.ɵqud(603979776, 2, { tplFirst: 0 }), i1.ɵqud(603979776, 3, { tplLast: 0 }), i1.ɵqud(603979776, 4, { tplNext: 0 }), i1.ɵqud(603979776, 5, { tplNumber: 0 }), i1.ɵqud(603979776, 6, { tplPrevious: 0 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_CustomPaginatorComponent_2)), i1.ɵdid(9, 16384, [[6, 4]], 0, i3.NgbPaginationPrevious, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_CustomPaginatorComponent_3)), i1.ɵdid(11, 16384, [[4, 4]], 0, i3.NgbPaginationNext, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_CustomPaginatorComponent_4)), i1.ɵdid(13, 16384, [[2, 4]], 0, i3.NgbPaginationFirst, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_CustomPaginatorComponent_5)), i1.ɵdid(15, 16384, [[3, 4]], 0, i3.NgbPaginationLast, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = _co.count; var currVal_3 = _co.maxSize; var currVal_4 = _co.currentPage; var currVal_5 = _co.pageSize; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_CustomPaginatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nav", [["aria-label", "Page navigation example"], ["class", "ml-auto"], ["id", "custom-pagination-nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomPaginatorComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.count; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomPaginatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-paginator", [], null, null, null, View_CustomPaginatorComponent_0, RenderType_CustomPaginatorComponent)), i1.ɵdid(1, 638976, null, 0, i5.CustomPaginatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomPaginatorComponentNgFactory = i1.ɵccf("app-custom-paginator", i5.CustomPaginatorComponent, View_CustomPaginatorComponent_Host_0, { pageSize: "pageSize", count: "count", page: "page" }, { pageChange: "pageChange" }, []);
export { CustomPaginatorComponentNgFactory as CustomPaginatorComponentNgFactory };
