import { AuthGuardService } from './shared/services/auth-guard.service';
import { EventsComponent } from './layout/events/events.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventInfoComponent } from './layout/events/event-info/event-info.component';
import { EventListComponent } from './layout/events/event-list/event-list.component';
import { InvestorCompanyComponent } from './layout/investor-company/investor-company.component';
import { PrintsComponent } from './layout/prints/prints.component';
import { InvestorSchedulePrintComponent } from './layout/prints/investor-schedule-print/investor-schedule-print.component';
import { CompanySchedulePrintComponent } from './layout/prints/company-schedule-print/company-schedule-print.component';
import { WebExComponent } from './layout/web-ex/web-ex.component';

const routes: Routes = [
  { path: '', redirectTo: '/regis/event', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'print', component: PrintsComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'schedule-investor/:investorId', component: InvestorSchedulePrintComponent },
      { path: 'schedule-company/:participantId', component: CompanySchedulePrintComponent }
    ]
  },
  {
    path: 'regis', component: LayoutComponent, canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'event', pathMatch: 'full' },
      {
        path: 'event', component: EventsComponent,
        children: [
          { path: '', component: EventListComponent },
          { path: 'create', component: EventInfoComponent },
          {
            path: ':eventAlias',
            loadChildren: () => import('./layout/event/event.module').then(m => m.EventModule)
          }
        ]
      },
      { path: 'investor-company', component: InvestorCompanyComponent },
      { path: 'web-ex', component: WebExComponent }
    ]
  },
  { path: '', redirectTo: 'regis', pathMatch: 'full' },
  { path: '**', redirectTo: 'regis', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
