import { EntRegisAuthService } from './../../shared/services/ent-regis-auth.service';
import { Configuration } from '@ent-regis/entregis-ts-angular';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthProvider {
    constructor(
        private apiConfig: Configuration,
        private entRegisAuthService: EntRegisAuthService
    ) {

    }

    async onAppLoaded(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (!this.apiConfig.apiKeys) {
                this.apiConfig.apiKeys = {};
            }
            this.apiConfig.apiKeys.Authorization = 'Bearer ' + this.entRegisAuthService.token;
            resolve();
        });
    }
}
