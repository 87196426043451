import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class LoginComponent {
    constructor(adalService, router, entRegisAuthService, apiConfiguration) {
        this.adalService = adalService;
        this.router = router;
        this.entRegisAuthService = entRegisAuthService;
        this.apiConfiguration = apiConfiguration;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.adalService.userInfo.authenticated) {
                const isAuthenticated = this.entRegisAuthService.isAuthenticated;
                if (typeof isAuthenticated === 'boolean') {
                    this.decide(isAuthenticated);
                }
                else {
                    const isAuthen = yield isAuthenticated.toPromise();
                    this.decide(isAuthen);
                }
            }
            else {
                // do nothing
            }
        });
    }
    decide(isAuthen) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (isAuthen) {
                this.goInside();
            }
            else {
                const response = yield this.entRegisAuthService.getTokenWithAzureToken(this.adalService.getToken()).toPromise();
                const accessToken = response.access_token;
                const refreshToken = response.refresh_token;
                this.entRegisAuthService.updateToken(accessToken, refreshToken);
                this.goInside();
            }
        });
    }
    login() {
        this.adalService.login();
    }
    goInside() {
        const comeBackTo = localStorage.getItem('comeBackTo');
        if (comeBackTo) {
            localStorage.removeItem('comeBackTo');
            window.location.href = comeBackTo;
        }
        else {
            this.router.navigate(['/']);
        }
    }
}
