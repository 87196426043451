<div class="container-custom mt-3 mb-3">
  <div class="table-responsive-lg">
    <table id="investor-company-table" class="table">
      <thead>
        <tr>
          <th id="no-column" scope="col"></th>
          <th id="company-name-column" scope="col">COMPANY NAME</th>
          <th id="approve-column" scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr *ngFor="let company of companies; let i = index;">
            <td [id]="'no-text-' + i" scope="row">{{ i + 1 }}</td>
            <td>
              <div class="d-flex align-items-center">
                <span class="dot" *ngIf="!company.isApproved"></span>
                <span [id]="'company-name-text-' + i" *ngIf="modes[i] === InvestorCompanyMode.idle">{{company.companyName}}</span>
                <span *ngIf="modes[i] === InvestorCompanyMode.edit">
                  <div class="d-flex flex-row">
                    <input [id]="'investor-company-field-company-input' + i" type="text" class="form-control" style="width: 400px;" [value]="company.companyName" [(ngModel)]="nameEdit[i]">
                    <button [id]="'investor-company-update-action-button-' + i" class="btn btn-outline-primary border-0" (click)="updateCompany(company.investorCompanyId, nameEdit[i], i); ">
                      <fa-icon class="add-button ml-2" [icon]="faCheck"></fa-icon>
                    </button>
                    <button [id]="'investor-company-cancel-action-button-' + i" class="btn btn-outline-danger border-0" (click)="modes[i] = InvestorCompanyMode.idle">
                      <fa-icon class="add-button ml-2" [icon]="faTimes"></fa-icon>
                    </button>
                  </div>
                </span>

              </div>
            </td>
            <td class="d-flex">
              <button [id]="'investor-company-hide-button-' + i" 
                class="btn btn-outline-danger border-0 mr-1" (click)="hideCompany(company)" placement="auto" [ngbTooltip]="'Hide Company'">
                <fa-icon class="add-button" [icon]="faEyeSlash"></fa-icon>
              </button>

              <button *ngIf="modes[i] === InvestorCompanyMode.idle" [id]="'investor-company-edit-button-' + i" 
                class="btn btn-outline-secondary border-0 mr-1" (click)="modes[i] = InvestorCompanyMode.edit; nameEdit[i] = company.companyName">
                <fa-icon class="add-button" [icon]="faEdit"></fa-icon>
              </button>

              <ng-select *ngIf="!company.isApproved" [id]="'investor-company-field-company-selector-' + i" 
                style="width: 400px;" placeholder="Merge To" [searchable]="true"
                [clearable]="true" [virtualScroll]="true" (change)="onSelected($event, company)">
                <ng-option [id]="'investor-company-field-approve-option-' + i"  [value]="'approve'"><strong>Approve As A New Company</strong></ng-option>
                <ng-option disabled>
                  --------------------------------------------------------------------------------------------------------
                </ng-option>
                <ng-option *ngFor="let item of companiesDropdown; let j = index;" [value]=item.investorCompanyId>
                  <span [id]="'investor-company-field-company-name-option-' + i + '-' + j"></span>{{item.companyName}}
                </ng-option>
              </ng-select>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<app-confirm-modal #confirmModal ></app-confirm-modal>
