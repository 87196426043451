import { BehaviorSubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { accessTokenKey, refreshTokenKey } from 'src/app/config/app-constant';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ent-regis/entregis-ts-angular/configuration";
import * as i2 from "@angular/common/http";
export class EntRegisAuthService {
    constructor(apiConfig, httpClient) {
        this.apiConfig = apiConfig;
        this.httpClient = httpClient;
        this.refreshTokenInProgress = false;
        this.accessTokenSubject = new BehaviorSubject(null);
        this.refreshTokenSubject = new BehaviorSubject(null);
        this.accessTokenSubject.next(this.token);
        this.refreshTokenSubject.next(this.refreshToken);
    }
    get token() {
        return localStorage.getItem(accessTokenKey);
    }
    get refreshToken() {
        return localStorage.getItem(refreshTokenKey);
    }
    doesTokenExpired() {
        return this.token == null
            ? true
            : jwt_decode(this.token).exp < Date.now() / 1000;
    }
    doesRefreshTokenExpired() {
        return this.refreshToken == null
            ? true
            : jwt_decode(this.refreshToken).exp < Date.now() / 1000;
    }
    get isAuthenticated() {
        if (this.token === null || this.token === undefined) {
            return false;
        }
        else if (this.doesTokenExpired()) {
            if (this.doesRefreshTokenExpired()) {
                return false;
            }
            else {
                return this.refresh();
            }
        }
        else {
            return true;
        }
    }
    getUsername() {
        return this.token == null ? null : jwt_decode(this.token).user_name;
    }
    getRoles() {
        return this.token == null ? null : jwt_decode(this.token).authorities;
    }
    updateToken(token, refreshToken) {
        this.accessTokenSubject.next(token);
        this.refreshTokenSubject.next(refreshToken);
        localStorage.setItem(accessTokenKey, token);
        localStorage.setItem(refreshTokenKey, refreshToken);
        this.updateSwaggerToken();
    }
    updateSwaggerToken() {
        this.apiConfig.apiKeys.Authorization = 'Bearer ' + this.token;
    }
    refresh() {
        console.log('refresh token');
        this.refreshTokenSubject.next(null);
        this.accessTokenSubject.next(null);
        this.refreshTokenInProgress = true;
        return this.getTokenWithRefresh(this.refreshToken)
            .pipe(map((x) => {
            const accessToken = x.access_token;
            const refreshToken = x.refresh_token;
            this.updateToken(accessToken, refreshToken);
            this.refreshTokenInProgress = false;
            return true;
        }), catchError(() => of(false)));
    }
    getTokenWithAzureToken(azureToken) {
        const formData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('azure_token', azureToken);
        return this.httpClient.post(environment.baseUrl + '/oauth/token', formData, {
            headers: {
                Authorization: 'Basic ZW50LXJlZ2lzOmVudC1yZWdpcy1zZWNyZXQ='
            }
        });
    }
    getTokenWithRefresh(refreshToken) {
        const formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', refreshToken);
        return this.httpClient.post(environment.baseUrl + '/oauth/token', formData, {
            headers: {
                Authorization: 'Basic ZW50LXJlZ2lzOmVudC1yZWdpcy1zZWNyZXQ='
            }
        });
    }
}
EntRegisAuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntRegisAuthService_Factory() { return new EntRegisAuthService(i0.ɵɵinject(i1.Configuration), i0.ɵɵinject(i2.HttpClient)); }, token: EntRegisAuthService, providedIn: "root" });
