import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ent-regis/entregis-ts-angular/api/investorCompanyAdminController.service";
export class InvestorCompanyService {
    constructor(investorCompanyAdminService) {
        this.investorCompanyAdminService = investorCompanyAdminService;
        this.investorCompanies = new ReplaySubject(1);
        this.needApprove = new ReplaySubject(1);
        this.fetchAllData();
    }
    fetchAllData() {
        this.fetchInvestorCompanies();
        this.fetchNeedApprove();
    }
    fetchInvestorCompanies() {
        this.getAllInvestorCompanies((companies) => {
            this.investorCompanies.next(companies);
        });
    }
    getAllInvestorCompanies(completion) {
        this.investorCompanyAdminService.getAllInvestorCompaniesUsingGET().subscribe((result) => {
            if (completion) {
                completion(result.data);
            }
        });
    }
    approveInvestorCompany(investorCompanyId, completion) {
        this.investorCompanyAdminService.updateIsApproveByInvestorCompanyIdUsingPOST(investorCompanyId)
            .subscribe(() => {
            if (completion) {
                completion();
            }
        });
    }
    mergeInvestorCompany(fromInvestorCompanyId, toInvestorCompanyId, completion) {
        this.investorCompanyAdminService.mergeInvestorCompanyUsingPOST(fromInvestorCompanyId, toInvestorCompanyId)
            .subscribe(() => {
            if (completion) {
                completion();
            }
        });
    }
    fetchNeedApprove() {
        this.getNeedApprove((needApprove) => {
            this.needApprove.next(needApprove);
        });
    }
    getNeedApprove(completion) {
        this.investorCompanyAdminService.getNeedApproveUsingGET().subscribe((result) => {
            if (completion) {
                completion(result.data);
            }
        });
    }
}
InvestorCompanyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvestorCompanyService_Factory() { return new InvestorCompanyService(i0.ɵɵinject(i1.InvestorCompanyAdminControllerService)); }, token: InvestorCompanyService, providedIn: "root" });
