<div id="event-form-info" class="container-custom mt-4 mb-5">
  <form [formGroup]="form">
    <div id="event-form-info-fill-data-error-text" class="alert alert-danger" role="alert" *ngIf="form.dirty && form.invalid && isSubmitted">
      Please fill in the correct information.
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div id="event-form-info-event-infomation-card" class="card">
          <div class="card-body">
            <h5 id="event-form-info-event-infomation-title" class="card-title">Event Information</h5>
            <div class="row no-gutters">
              <div class="col-12 mb-3">
                <label id="event-form-info-field-event-type-text" for="event-form-info-field-event-type-input">Event Type <em class="text-danger">*</em></label><br>
                <div>
                  <div class="form-check form-check-inline">
                    <input id="event-form-info-field-event-type-input" class="form-check-input" type="radio" name="eventType" 
                      [value]="eventTypeEnum.DIGITALROADSHOW" formControlName="eventType">
                    <label id="event-form-info-field-event-type-digital-road-show-text" class="form-check-label" for="event-form-info-field-event-type-input">Digital Roadshow</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="event-form-info-field-event-type-thailand-focus-text" class="form-check-input" type="radio" name="eventType"
                      [value]="eventTypeEnum.THAILANDFOCUS" formControlName="eventType">
                    <label class="form-check-label" for="event-form-info-field-event-type-thailand-focus-text">Thailand Focus</label>
                  </div>
                </div>
                <small id="event-form-info-field-event-type-error-text" class="text-danger" *ngIf="f.eventType.dirty && f.eventType.invalid">
                  Event type is required
                </small>
              </div>      
              <div class="col-12 mb-3">
                <label id="event-form-info-field-event-period-text" for="event-form-info-field-event-period-input">Event Period<em class="text-danger">*</em></label>
                <input id="event-form-info-field-event-period-input" type="text" class="form-control" name="dateRange" formControlName="dateRange"
                  autocomplete="off" bsDaterangepicker
                  [bsConfig]="{ rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false, containerClass: 'theme-default' }">
                <small id="event-form-info-field-event-period-error-text" class="text-danger" *ngIf="f.dateRange.dirty && f.dateRange.invalid">
                  StartDate and EndDate is required
                </small>
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-event-name-text" for="event-form-info-field-event-name-input">Name <em class="text-danger">*</em></label>
                <input id="event-form-info-field-event-name-input" type="text" class="form-control" name="eventName" formControlName="eventName">
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-event-alias-text">Alias Name <em class="text-danger">*</em></label>
                <div class="d-flex flex-row">
                  <input id="event-form-info-field-event-alias-input" type="text" class="form-control" name="eventAlias" formControlName="eventAlias">
                  <div class="p-2 align-self-center" style="font-size: larger;">
                    <ng-template #tipContent>
                      <span>Only lowercase letters, number and special characters (-), example</span><br>
                      <b>"thailand-focus-20xx"</b>
                    </ng-template>
                    <fa-icon [icon]="faInfoCircle" placement="left" [ngbTooltip]="tipContent"></fa-icon>
                  </div>
                </div>
                <small id="event-form-info-field-event-alias-invlid-error-text" class="text-danger mt-1" *ngIf="form.controls.eventAlias.errors?.pattern">
                  Only lowercase letters, number and special characters (-)
                </small>
                <small id="event-form-info-field-event-alias-duplicated-error-text" class="text-danger mt-1" *ngIf="form.controls.eventAlias.errors?.duplicate">
                  Duplicated
                </small>
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-event-topic-name-text" for="event-form-info-field-event-topic-name-input">Prefix
                  topic for Webex <em class="text-danger">*</em></label>
                <div class="d-flex flex-row">
                  <input id="event-form-info-field-event-topic-name-input" type="text" class="form-control" name="location"
                    formControlName="prefixTopicWebex" maxlength="500">
                  <div class="p-2 align-self-center" style="font-size: larger;">
                    <ng-template #tipWebexTFContent>
                      <span>For create topic at webex </span><br>
                      <b>"Name (Symbol) : Thailand Focus 20xx"</b>
                    </ng-template>
                    <ng-template #tipWebexDRContent>
                      <span>For create topic at webex </span><br>
                      <b>"Name (Symbol) : Digital Roadshow - Qx/20xx Performance Review"</b>
                    </ng-template>
                    <fa-icon [icon]="faInfoCircle" placement="left" [ngbTooltip]="form.controls.eventType.value == eventTypeEnum.DIGITALROADSHOW ? tipWebexDRContent: tipWebexTFContent"></fa-icon>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-event-venue-text" for="event-form-info-field-event-venue-input">Venue</label>
                <input id="event-form-info-field-event-venue-input" type="text" class="form-control" name="location" formControlName="location" maxlength="500">
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-seminar-reminder-text" for="event-form-info-field-seminar-reminder-input">Seminar Reminder</label>
                <textarea id="event-form-info-field-seminar-reminder-input" name="reminder" class="form-control" formControlName="reminder"></textarea>
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-max-participant-text" for="event-form-info-field-max-participant-input">Max Participant</label>
                <input id="event-form-info-field-max-participant-input" type="number" class="form-control" name="participantLimit" formControlName="participantLimit" min="1" appNumbersOnly>
              </div>
              <div class="col-6 mb-3">
                <label id="event-form-info-field-hotel-text">Tab Hotel: <i>{{ isShowHotel ? 'Show' : 'Hide' }}</i></label>
                <p>
                  <ui-switch id="event-form-info-field-hotel-switch-input" [checked]="isShowHotel" (change)="onChangeHideHotel($event)"></ui-switch>
                </p>
              </div>
              <div class="col-6 mb-3">
                <label id="event-form-info-field-sale-person-text">Tab Invited by:  <i>{{ isShowSalePersonForInvestorRegistration ? 'Show' : 'Hide' }}</i></label>
                <p>
                  <ui-switch id="event-form-info-field-sale-person-switch-input" [checked]="isShowSalePersonForInvestorRegistration" (change)="onChangeShowSalePersonForInvestorRegistration($event)"></ui-switch>
                </p>
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-send-schedule-email-text">Backup schedule report:  <i>{{ isShowSendScheduleEmail ? 'On' : 'Off' }}</i></label>
                <p>
                  <ui-switch id="event-form-info-field-send-schedule-email-switch-input" [checked]="isShowSendScheduleEmail" (change)="onChangeShowSendScheduleEmail($event)"></ui-switch>
                </p>
              </div>

              
              <div class="col-12 mb-3">
                <label id="event-form-info-field-event-logo-text" class="pr-2" for="event-form-info-field-event-logo-input">Logo</label>
                <input id="event-form-info-field-event-logo-input" type="file" name="eventLogo" accept="image/*"
                  (change)="handleUpload($event, 'eventLogo')">
                <br>
                <div *ngIf="form.value.eventLogoBase64 || form.value.eventLogoUrl" class="upload-img-container">
                  <img id="event-form-info-field-event-logo-image" *ngIf="form.value.eventLogoBase64" alt="logo image" [src]="form.value.eventLogoBase64" style="max-height: 250px; max-width: 100%;" />
                  <img id="event-form-info-field-event-logo-url-image" *ngIf="!form.value.eventLogoBase64" alt="logo image" [src]="form.value.eventLogoUrl"
                    style="max-height: 250px; max-width: 100%;" />
                  <a>
                    <fa-icon id="event-form-info-field-event-logo-remove-icon" class="icon-delete" [icon]="faTimes" (click)="deleteImage('eventLogo')">x</fa-icon>
                  </a>
                </div>
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-banner-text" class="pr-2" for="event-form-info-field-banner-input">Banner</label>
                <input id="event-form-info-field-banner-input" type="file" name="banner" accept="image/*"
                  (change)="handleUpload($event, 'banner')">
                <br>
                <div *ngIf="form.value.bannerBase64 || form.value.bannerUrl" class="upload-img-container">
                  <img id="event-form-info-field-banner-image" *ngIf="form.value.bannerBase64" alt="banner image" [src]="form.value.bannerBase64" style="max-height: 250px; max-width: 100%;" />
                  <img id="event-form-info-field-banner-url-image" *ngIf="!form.value.bannerBase64" alt="banner image" [src]="form.value.bannerUrl"
                    style="max-height: 250px; max-width: 100%;" />
                  <a>
                    <fa-icon id="event-form-info-field-banner-remove-icon" class="icon-delete" [icon]="faTimes" (click)="deleteImage('banner')">x</fa-icon>
                  </a>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div id="event-form-info-registration-period-card" class="card">
          <div class="card-body">
            <h5 id="event-form-info-registration-period-title-text" class="card-title">Registration Period</h5>
            <div class="row no-gutters">
              <div class="col-12 mb-3">
                <label id="event-form-info-registration-period-field-company-text" for="event-form-info-registration-period-field-company-input">Company <em class="text-danger">*</em></label>
                <input id="event-form-info-registration-period-field-company-input" type="text" class="form-control" name="dateRangeRegisterCompany"
                  formControlName="dateRangeRegisterCompany" autocomplete="off" bsDaterangepicker
                  [bsConfig]="{ rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY',
                   showWeekNumbers: false, containerClass: 'theme-default' }">
                <small id="event-form-info-registration-period-field-company-error-text" class="text-danger"
                  *ngIf="f.dateRangeRegisterCompany.dirty && f.dateRangeRegisterCompany.invalid">
                  StartDate and EndDate is required
                </small>
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-registration-period-field-investor-text" for="event-form-info-registration-period-field-investor-input">Investor <em class="text-danger">*</em></label>
                <input id="event-form-info-registration-period-field-investor-input" type="text" class="form-control" name="dateRangeRegisterInvestor"
                  formControlName="dateRangeRegisterInvestor" autocomplete="off" bsDaterangepicker
                  [bsConfig]="{ rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY',
                   showWeekNumbers: false, containerClass: 'theme-default'}">
                <small id="event-form-info-registration-period-field-investor-error-text" class="text-danger"
                  *ngIf="f.dateRangeRegisterInvestor.dirty && f.dateRangeRegisterInvestor.invalid">
                  StartDate and EndDate is required
                </small>
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-registration-period-field-seminar-investor-text" for="event-form-info-registration-period-field-seminar-investor-input">Seminar <em class="text-danger">*</em></label>
                <input id="event-form-info-registration-period-field-seminar-investor-input" type="text" class="form-control"
                  name="dateRangeRegisterSeminarInvestor" formControlName="dateRangeRegisterSeminarInvestor"
                  autocomplete="off" bsDaterangepicker
                  [bsConfig]="{ rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY',
                   showWeekNumbers: false, containerClass: 'theme-default' }">
                <small id="event-form-info-registration-period-field-seminar-investor-error-text" class="text-danger"
                  *ngIf="f.dateRangeRegisterSeminarInvestor.dirty && f.dateRangeRegisterSeminarInvestor.invalid">
                  StartDate and EndDate is required
                </small>
              </div>
            </div>
          </div>
        </div>
        <div id="event-form-info-theme-and-color-card" class="card mt-3">
          <div class="card-body">
            <h5 id="event-form-info-theme-and-color-title" class="card-title">Theme & Color</h5>
            <div class="row no-gutters">
              <div class="col-12">
                <div class="mb-3">
                  <label id="event-form-info-field-background-text" class="pr-2" for="event-form-info-field-background-input">Background</label>
                <input id="event-form-info-field-background-input" type="file" name="background" accept="image/*"
                  (change)="handleUpload($event, 'background')">
                <br>
                <div *ngIf="form.value.backgroundBase64 || form.value.backgroundUrl" class="upload-img-container">
                  <img id="event-form-info-field-background-image" *ngIf="form.value.backgroundBase64" alt="background image" [src]="form.value.backgroundBase64" style="max-height: 250px; max-width: 100%;" />
                  <img id="event-form-info-field-background-url-image" *ngIf="!form.value.backgroundBase64" alt="background image" [src]="form.value.backgroundUrl"
                    style="max-height: 250px; max-width: 100%;" />
                  <a>
                    <fa-icon id="event-form-info-field-background-remove-icon" class="icon-delete" [icon]="faTimes" (click)="deleteImage('background')">x</fa-icon>
                  </a>
                </div>
                </div>
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-company-color-text" for="event-form-info-field-company-color-input">Company Color</label>
                <input id="event-form-info-field-company-color-input" class="form-control" placeholder="Pick or enter color"
                  [cpOKButton]="true" [cpOKButtonText]="'Select'" [cpOKButtonClass]="'btn btn-primary btn-xs'" cpPosition="left"
                  [cpSaveClickOutside]="false" [cpPresetColors]="cpPresetColors"
                  [value]="companyColor" [(colorPicker)]="companyColor" [style.background]="companyColor" />
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-investor-color-text" for="event-form-info-field-investor-color-input">Investor Color</label>
                <input id="event-form-info-field-investor-color-input" class="form-control" placeholder="Pick or enter color"
                  [cpOKButton]="true" [cpOKButtonText]="'Select'" [cpOKButtonClass]="'btn btn-primary btn-xs'" cpPosition="left"
                  [cpSaveClickOutside]="false" [cpPresetColors]="cpPresetColors"
                  [value]="investorColor" [(colorPicker)]="investorColor" [style.background]="investorColor" />
              </div>
              <div class="col-12 mb-3">
                <label id="event-form-info-field-seminar-investor-color-text" for="event-form-info-field-seminar-investor-color-input">Seminar Investor Color</label>
                <input id="event-form-info-field-seminar-investor-color-input" class="form-control" placeholder="Pick or enter color"
                  [cpOKButton]="true" [cpOKButtonText]="'Select'" [cpOKButtonClass]="'btn btn-primary btn-xs'" cpPosition="left"
                  [cpSaveClickOutside]="false" [cpPresetColors]="cpPresetColors"
                  [value]="seminarInvestorColor" [(colorPicker)]="seminarInvestorColor" [style.background]="seminarInvestorColor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button id="event-form-info-save-button" type="button" class="btn btn-primary my-3" (click)="onSave()">SAVE</button>
  </form>
</div>