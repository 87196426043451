import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CheckInControllerService } from '@ent-regis/entregis-ts-angular';
import { CheckInAdminRequest } from '@ent-regis/entregis-ts-angular/model/checkInAdminRequest';
import { BsModalRef } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { Printer } from 'typescript';
import { capitalizeFirstLetter } from '../../functions/string-formatter.function';
import { PrinterService, QZ_TRAY_CONNECT_STATUS } from '../../services/printer.service';

@Component({
  selector: 'app-print-badge-modal',
  templateUrl: './print-badge-modal.component.html',
  styleUrls: ['./print-badge-modal.component.scss']
})
export class PrintBadgeModalComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  qzConnectStatus: QZ_TRAY_CONNECT_STATUS;
  printers: Printer[];
  selectedPrinterName: string = null;
  alertText: string = '';

  firstName: string;
  lastName: string;
  companyName: string;
  attendeeType: CheckInAdminRequest.UserTypeEnum;
  id: number;
  color: string
  @Output() success = new EventEmitter<null>();

  constructor(
    public bsModalRef: BsModalRef,
    public checkInService: CheckInControllerService,
    private printerService: PrinterService,
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = new Subscription();
    this.connectQZ();
    this.subscription.add(this.printerService.qzConnectStatus.subscribe(x => {
      this.qzConnectStatus = x;
      console.log("qzConnectStatus: " + this.qzConnectStatus);
    }));
  }

  cancel() {
    this.bsModalRef.hide();
  }

  print() {
    if (this.qzConnectStatus === 'CONNECTED') {
      this.alertText = null;
      const request: CheckInAdminRequest = {
        id: this.id,
        userType: this.attendeeType
      }
      this.checkInService.checkInBadgeUsingPUT(request).subscribe(() => {
        const firstName = this.firstName ? capitalizeFirstLetter(this.firstName.toLocaleLowerCase()) : "";
        const lastName = this.lastName ? capitalizeFirstLetter(this.lastName.toLocaleLowerCase()) : "";
        this.printerService
          .print(
            this.selectedPrinterName,
            this.getZPL(`${firstName} ${lastName}`, this.companyName)
          );
        this.success.emit();
        this.bsModalRef.hide();
      })
    } else {
      this.alertText = "Couldn't connect to the printer.";
    }

  }

  connectQZ() {
    this.printerService.connectQZTray()
      .then(() => {
        this.printerService.getDefaultPrinterName().then((x) => {
          this.selectedPrinterName = x;
        })
      });
  }

  getZPL(personName: string, personCompanyName: string) {
    const qrContent = "";
    return `^XA

    ^FX Second section with recipient address and permit information.
    ^CF0,60
    ^FB812,2,0,C^FO0,100^FD${personName}^FS
    ^CF0,50
    ^FB812,2,0,C^FO0,220^FD${personCompanyName}^FS

    //^B0R,7,N,0,N,1,0
    //^FD${qrContent}^FS
    ^XZ`;
  }

}