import { ViewportScroller } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventAdminControllerService, EventInfoRequest, EventInfoResponse } from '@ent-regis/entregis-ts-angular';
import { faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { appConst } from 'src/app/constants/app-const';
import { FormUtilsService } from '../../services/form-utils.service';

@Component({
  selector: 'app-event-form-info',
  templateUrl: './event-form-info.component.html',
  styleUrls: ['./event-form-info.component.scss']
})
export class EventFormInfoComponent implements OnInit, OnChanges {

  form: FormGroup;
  get f() {
    return this.form.controls;
  }
  eventTypeEnum = EventInfoResponse.EventTypeEnum;
  public faInfoCircle = faInfoCircle;
  public faTimes = faTimesCircle;

  isShowHotel = true;
  isShowSalePersonForInvestorRegistration = true;
  isShowSendScheduleEmail = false;

  isSubmitted = false;
  isDeleteLogo = false;
  isDeleteBanner = false;
  isDeleteBackground = false;

  companyColor: string = null;
  investorColor: string = null;
  seminarInvestorColor: string = null;
  cpPresetColors = ['#fff', '#000', '#C0C0C0', '#0000FF', '#8A2BE2', '#2889e9', '#5DADE2', '#008000', '#52BE80', '#fff500', '#e920e9', '#FF0000', '#EC7063'];

  @Input()
  eventInfo: EventInfoResponse;
  @Output() saveSuccess = new EventEmitter<{ isSuccess: boolean, eventAlias: string }>();

  constructor(
    private eventAdminService: EventAdminControllerService,
    private viewPortScroller: ViewportScroller,
    private formUtils: FormUtilsService
  ) { }

  ngOnChanges(): void {
    if (this.eventInfo) {
      this.isShowHotel = this.eventInfo.isShowHotel || false;
      this.isShowSalePersonForInvestorRegistration = this.eventInfo.isShowSalePersonForInvestorRegistration || false;
      this.isShowSendScheduleEmail = this.eventInfo.isSendScheduleEmail || false;
      this.companyColor = this.eventInfo.companyColor || null;
      this.investorColor = this.eventInfo.investorColor || null;
      this.seminarInvestorColor = this.eventInfo.seminarInvestorColor || null;
      if (this.form) {
        this.form.patchValue({
          eventLogoUrl: this.eventInfo.eventLogoUrl,
          eventLogoBase64: null,
          eventLogoFileName: null,
          eventName: this.eventInfo.eventName,
          eventType: this.eventInfo.eventType,
          bannerUrl: this.eventInfo.bannerUrl,
          prefixTopicWebex: this.eventInfo.prefixTopicWebex,
          bannerBase64: null,
          bannerFileName: null,
          eventAlias: this.eventInfo.eventAlias,
          dateRange: [
            new Date(this.eventInfo.startDate),
            new Date(this.eventInfo.endDate)
          ],
          dateRangeRegisterCompany: [
            new Date(this.eventInfo.startDateRegisterCompany),
            new Date(this.eventInfo.endDateRegisterCompany)
          ],
          dateRangeRegisterInvestor: [
            new Date(this.eventInfo.startDateRegisterInvestor),
            new Date(this.eventInfo.endDateRegisterInvestor)
          ],
          dateRangeRegisterSeminarInvestor: [
            new Date(this.eventInfo.startDateRegisterSeminarInvestor),
            new Date(this.eventInfo.endDateRegisterSeminarInvestor)
          ],
          reminder: this.eventInfo.reminderText,
          location: this.eventInfo.location,
          participantLimit: this.eventInfo.participantLimit || 5,
          backgroundUrl: this.eventInfo.bgUrl,
          backgroundBase64: null,
          backgroundFileName: null,
        });
        if (this.eventInfo.eventType === this.eventTypeEnum.DIGITALROADSHOW) {
          this.form.controls.eventType.disable();
          this.form.controls.dateRange.disable();
          this.form.controls.dateRangeRegisterCompany.disable();
          this.form.updateValueAndValidity();
        }
      }
    }

  }

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    const today = new Date();
    this.form = new FormGroup({
      eventLogoBase64: new FormControl(null),
      eventLogoFileName: new FormControl(null),
      eventLogoUrl: new FormControl(null),
      eventName: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      eventType: new FormControl(null, Validators.required),
      bannerBase64: new FormControl(null),
      bannerFileName: new FormControl(null),
      bannerUrl: new FormControl(null),
      backgroundBase64: new FormControl(null),
      backgroundFileName: new FormControl(null),
      backgroundUrl: new FormControl(null),
      eventAlias: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.pattern(appConst.aliasPattern)]),
      dateRange: new FormControl(null, Validators.required),
      dateRangeRegisterCompany: new FormControl(null, Validators.required),
      dateRangeRegisterInvestor: new FormControl(null, Validators.required),
      dateRangeRegisterSeminarInvestor: new FormControl(null, Validators.required),
      location: new FormControl(null),
      reminder: new FormControl(null),
      prefixTopicWebex: new FormControl(`Thailand Focus ${today.getFullYear()}`, Validators.required),
      participantLimit: new FormControl(5, Validators.required),
    });
  }

  handleUpload(event: any, id: string): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (id === 'eventLogo') {
        this.form.controls.eventLogoBase64.setValue(reader.result);
        this.form.controls.eventLogoFileName.setValue(file.name);
      } else if (id === 'banner') {
        this.form.controls.bannerBase64.setValue(reader.result);
        this.form.controls.bannerFileName.setValue(file.name);
      } else {
        this.form.controls.backgroundBase64.setValue(reader.result);
        this.form.controls.backgroundFileName.setValue(file.name);
      }
      this.form.updateValueAndValidity();
    };
  }

  deleteImage(id: string): void {
    if (id === 'eventLogo') {
      (<HTMLInputElement>document.getElementById('event-form-info-field-event-logo-input')).value = '';
      this.form.controls.eventLogoUrl.setValue(null);
      this.form.controls.eventLogoBase64.setValue(null);
      this.form.controls.eventLogoFileName.setValue(null);
      this.isDeleteLogo = true;
    } else if (id === 'banner') {
      (<HTMLInputElement>document.getElementById('event-form-info-field-banner-input')).value = '';
      this.form.controls.bannerUrl.setValue(null);
      this.form.controls.bannerBase64.setValue(null);
      this.form.controls.bannerFileName.setValue(null);
      this.isDeleteBanner = true;
    } else {
      (<HTMLInputElement>document.getElementById('event-form-info-field-background-input')).value = '';
      this.form.controls.backgroundUrl.setValue(null);
      this.form.controls.backgroundBase64.setValue(null);
      this.form.controls.backgroundFileName.setValue(null);
      this.isDeleteBackground = true;
    }
    this.form.updateValueAndValidity();
  }

  onSave() {
    this.isSubmitted = true;
    if (this.form.valid) {
      this.eventAliasChanged(() => {
        const form = this.form.getRawValue();
        const request: EventInfoRequest = {
          eventName: form.eventName,
          eventLogoBase64: form.eventLogoBase64,
          eventLogoFileName: form.eventLogoFileName,
          bannerBase64: form.bannerBase64,
          bannerFileName: form.bannerFileName,
          bgBase64: form.backgroundBase64,
          bgFileName: form.backgroundFileName,
          eventAlias: form.eventAlias,
          eventType: form.eventType,
          startDate: form.dateRange[0],
          endDate: form.dateRange[1],
          startDateRegisterCompany: form.dateRangeRegisterCompany[0],
          endDateRegisterCompany: form.dateRangeRegisterCompany[1],
          startDateRegisterInvestor: form.dateRangeRegisterInvestor[0],
          endDateRegisterInvestor: form.dateRangeRegisterInvestor[1],
          startDateRegisterSeminarInvestor: form.dateRangeRegisterSeminarInvestor[0],
          endDateRegisterSeminarInvestor: form.dateRangeRegisterSeminarInvestor[1],
          hasUpdateLogo: this.hasUpdateLogo(),
          hasUpdateBanner: this.hasUpdateBanner(),
          hasUpdateBg: this.hasUpdateBg(),
          isShowHotel: this.isShowHotel,
          isShowSalePersonForInvestorRegistration: this.isShowSalePersonForInvestorRegistration,
          reminderText: form.reminder,
          location: form.location,
          companyColor: this.companyColor,
          investorColor: this.investorColor,
          seminarInvestorColor: this.seminarInvestorColor,
          participantLimit: form.participantLimit,
          isSendScheduleEmail: this.isShowSendScheduleEmail,
          prefixTopicWebex : form.prefixTopicWebex
        };
        if (this.eventInfo) {
          this.eventAdminService.updateEventInfoUsingPOST(this.eventInfo.eventId, request).subscribe(() => {
            this.saveSuccess.emit({ isSuccess: true, eventAlias: request.eventAlias });
          });
        } else {
          this.eventAdminService.submitEventUsingPOST(request).subscribe(() => {
            this.saveSuccess.emit({ isSuccess: true, eventAlias: null });
          });
        }
      });
    } else {
      this.form.markAllAsTouched();
      this.viewPortScroller.scrollToPosition([0, 0]);
    }
  }

  eventAliasChanged(complete: () => void) {
    if (this.form.controls.eventAlias.dirty) {
      if (this.eventInfo) {
        if (this.form.value.eventAlias != this.eventInfo.eventAlias) {
          this.eventAdminService.checkAliasDuplicateUsingGET(this.form.value.eventAlias).subscribe((res) => {
            if (res.data) {
              this.formUtils.setError(this.form.controls.eventAlias, 'duplicate', true);
              document.getElementById("event-form-info-field-event-alias-text").scrollIntoView();
            } else {
              this.formUtils.removeError(this.form.controls.eventAlias, 'duplicate');
              if (complete) { complete(); }
            }
          });
        }
      } else {
        this.eventAdminService.checkAliasDuplicateUsingGET(this.form.value.eventAlias).subscribe((res) => {
          if (res.data) {
            this.formUtils.setError(this.form.controls.eventAlias, 'duplicate', true);
            document.getElementById("event-form-info-field-event-alias-text").scrollIntoView();
          } else {
            this.formUtils.removeError(this.form.controls.eventAlias, 'duplicate');
            if (complete) { complete(); }
          }
        });
      }
    } else {
      if (complete) { complete(); }
    }
  }

  onClipboard(event: string) {
    const el = document.createElement('textarea');
    el.value = event;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  hasUpdateLogo(): boolean {
    return this.form.value.eventLogoBase64 !== null || (this.isDeleteLogo && this.eventInfo.eventLogoUrl !== null && this.form.value.eventLogoBase64 === null);
  }

  hasUpdateBanner(): boolean {
    return this.form.value.bannerBase64 !== null || (this.isDeleteBanner && this.eventInfo.bannerUrl !== null && this.form.value.bannerBase64 === null);
  }


  hasUpdateBg(): boolean {
    return this.form.value.backgroundBase64 !== null || (this.isDeleteBackground && this.eventInfo.bgUrl !== null && this.form.value.backgroundBase64 === null);
  }

  onChangeHideHotel(value: boolean) {
    this.isShowHotel = value;
  }

  onChangeShowSalePersonForInvestorRegistration(value: boolean) {
    this.isShowSalePersonForInvestorRegistration = value;
  }

  onChangeShowSendScheduleEmail(value: boolean) {
    this.isShowSendScheduleEmail = value;
  }

}
