<ng-template #template>
  <div id="send-schedule-modal" class="modal-content" style="width: 1000px;">
    <div class="modal-header">
      <h6 id="send-schedule-title-text" class="modal-title text-uppercase">Send Schedule</h6>
      <button type="button" class="close" (click)=close()>
        <span id="send-schedule-close-button" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-left">
      <div>
        <div class="row mb-3">
          <div class="col-12 mb-md-3">
            <label id="send-schedule-field-template-text">Template</label>
            <select id="send-schedule-field-template-selector" class="form-control" (change)="selectTemplate()" [(ngModel)]="templateId">
              <option value="null" disabled>Select template.</option>
              <option *ngFor="let item of templates" [value]="item.templateId">{{item.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="templateId && preview" class="mb-3">
          <label id="send-schedule-field-preview-text" class="title">Preview</label>
          <hr>
          <div *ngIf="templateType === 'participantSchedule'">
            <div class="d-flex align-items-center mb-3">
              <span id="send-schedule-field-participant-schedule-to-text" class="pr-3"><b>To :</b></span>
              <ng-select id="send-schedule-field-to-selector" [multiple]=true placeholder="Select Receiver" [closeOnSelect]="false" style="min-width: 500px;"
              appendTo="body" [(ngModel)]="selectedParticipants" (change)="onSelectedParticipants()">
              <ng-option *ngFor="let participant of participants; let i = index;" [value]=participant.participantId>
                <span [id]="'send-schedule-field-participant-schedule-to-participant-option-'+i">{{participant.firstName}} {{participant.lastName}}</span>
              </ng-option>
            </ng-select>
            </div>
            <div>
              <a id="send-schedule-field-participant-schedule-selected-participant-link" class="link" *ngFor="let participant of selectedPreviewParticipants"
              (click)="previewSelectedId = participant.participantId;selectPreviewTemplate();">
              <span [ngClass]="{'bg-secondary': previewSelectedId === participant.participantId}" 
              class="m-1 px-2 py-1 border border-secondary rounded">
              {{participant.firstName}} {{participant.lastName}} {{'<' + participant.email + '>'}}</span>
            </a></div>
          </div>
          <div *ngIf="templateType === 'investorSchedule'">
            <p>
              <b id="send-schedule-field-investor-schedule-to-title-text">To :</b> 
              <span id="send-schedule-field-investor-schedule-to-text" class="mx-1 px-2 py-1 border border-secondary rounded"> {{preview.to}}</span>
            </p>
            <p>
              <b id="send-schedule-field-investor-schedule-cc-title-text">Cc :</b> 
              <span id="send-schedule-field-investor-schedule-cc-text" class="mx-1 px-2 py-1 border border-secondary rounded"> {{preview.cc}}</span>
            </p>
          </div>
          <p><b id="send-schedule-field-investor-schedule-subject-title-text">Subject :</b> 
            <span id="send-schedule-field-investor-schedule-subject-text">{{preview.previewSubject}}</span></p>
          <div class="border border-secondary p-3">
            <span id="send-schedule-content-text" [innerHTML]="content" ></span>
          </div>
        </div>
        <div class="text-center">
          <button id="send-schedule-cancel-button" type="button" class="btn btn-secondary mt-1" (click)="close()">CANCEL</button>
          <button id="send-schedule-send-button" type="button" class="btn btn-primary ml-2 mt-1" (click)="submit()"
            [disabled]="!templateId">SEND</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>