<div class="container-custom h-100 d-flex justify-content-center">
    <div id="my-card" class="jumbotron my-auto">
        <!-- <div class="d-flex justify-content-center mb-4">
            <img src="/assets/images/thumbnail.png" width="auto" height="40px" alt="">
        </div> -->
        <h4 id="login-system-name-text" class="d-flex justify-content-center">Enterprise Registration</h4>
        <p id="login-welcome-text" class="d-flex justify-content-center mb-4">Welcome to Stock Exchange of Thailand Registration</p>
        <div class="d-flex justify-content-center">
            <button id="login-button" class="btn btn-lean" (click)=login()><img src="/assets/images/ms-logo.png" width="36px" height="36px" alt="">LOGIN to SET MS Office 365</button>
        </div>
    </div>
</div>
