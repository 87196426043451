import { Component, OnInit, Input } from '@angular/core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit {

  faAngleRight = faAngleRight;

  @Input() parentLabel: string;
  @Input() elementLabel: string;

  constructor() { }

  ngOnInit() {
  }

  clickParent() {
  }
}
