import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CustomPaginatorComponent } from './custom-paginator/custom-paginator.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { PadcSelectorComponent } from './padc-selector/padc-selector.component';
import { RealtimeSearchBarComponent } from './realtime-search-bar/realtime-search-bar.component';
import { SendScheduleModalComponent } from './send-schedule-modal/send-schedule-modal.component';
import { UpdateStatusModalComponent } from './update-status-modal/update-status-modal.component';
import { MatDividerModule, MatIconModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PrintBadgeModalComponent } from './print-badge-modal/print-badge-modal.component';
import { PipesModule } from '../modules/pipes/pipes.module';
import { EventFormInfoComponent } from './event-form-info/event-form-info.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { BsDatepickerModule, TooltipModule } from 'ngx-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
import { DirectivesModule } from '../modules/directives/directive.module';
import { MeetingTypePipe } from '../pipes/meeting-type.pipe';
import { RoomTypePipe } from '../pipes/room-type.pip';

@NgModule({
  entryComponents: [
    SendScheduleModalComponent,
    PrintBadgeModalComponent
  ],
  declarations: [
    NavigatorComponent,
    RealtimeSearchBarComponent,
    PadcSelectorComponent,
    CustomPaginatorComponent,
    ConfirmModalComponent,
    UpdateStatusModalComponent,
    SendScheduleModalComponent,
    AlertModalComponent,
    PrintBadgeModalComponent,
    EventFormInfoComponent,
    MeetingTypePipe,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    PipesModule,
    UiSwitchModule,
    BsDatepickerModule,
    TooltipModule.forRoot(),
    NgbModule,
    ColorPickerModule,
    DirectivesModule,

  ],
  exports: [
    NavigatorComponent,
    RealtimeSearchBarComponent,
    PadcSelectorComponent,
    CustomPaginatorComponent,
    ConfirmModalComponent,
    UpdateStatusModalComponent,
    SendScheduleModalComponent,
    AlertModalComponent,
    PrintBadgeModalComponent,
    EventFormInfoComponent,
    MeetingTypePipe,
  ]
})
export class SharedComponentsModule { }
