<div class="container-custom mt-3 mb-3">
  <h3>Webex</h3>
  <hr>
  <div class="">
    <div *ngFor="let item of listWebEx">
      <h6>Event User : <span class="font-weight-normal">{{item.usertype}}</span></h6>
      <h6>Client ID : <span class="font-weight-normal">{{item.clientId}}</span></h6>
      <div class="d-flex align-items-center">
        <h6 id="web-ex-link-field-company-text">Username :</h6>
        <div class="input-group mb-3 mx-2 w-25">
          <input id="web-ex-link-field-company-input" type="text" class="form-control d-inline" [value]="item.username"
            disabled>
          <div class="input-group-append">
            <button id="web-ex-link-field-company-clipboard-button" class="btn btn-sm btn-secondary" type="button"
              name="username" placement="top" ngbTooltip="Copy URL to clipboard" (click)="onClipboard(item.username)">
              <fa-icon [icon]="faCopy"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-primary my-2" (click)="connectWebEx(item)">CONNECT</button>
      <hr>
    </div>

  </div>
</div>