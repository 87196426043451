<!-- <header>
	<div class="container">
		<a href="https://www.set.or.th/th/index.html" class="logo-set-th img-fluid"></a>
	</div>
</header> -->
<nav class="navbar regis-header">
	<a class="navbar-brand">
		<!-- <img id="set-logo-image" src="/assets/images/thumbnail.png" width="auto" height="40" alt=""> -->
		<img id="set-logo-image" src="/assets/images/logo_set_50.png" width="auto" height="40" alt="">
		<span id="system-name-text" style="color: black; font-size: larger; vertical-align: middle;">&nbsp;&nbsp;Enterprise Registration (SET Admin)</span>
	</a>
	<div>
		<button id="username-button" class="btn btn-lean"><fa-icon [icon]="faUser"></fa-icon> {{name}}</button>
		<button id="logout-button" class="btn btn-lean" (click)=logout()>Logout</button>
	</div>
	
</nav>