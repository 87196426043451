import * as i0 from "@angular/core";
export class FormUtilsService {
    constructor() { }
    setError(form, errorName, errorValue) {
        var error = form.errors;
        if (error == null) {
            error = {};
        }
        error[errorName] = errorValue;
        form.setErrors(error);
    }
    removeError(form, errorName) {
        var error = form.errors;
        if (error) {
            delete error[errorName];
            if (Object.keys(error).length == 0) {
                error = null;
            }
            form.setErrors(error);
        }
    }
}
FormUtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormUtilsService_Factory() { return new FormUtilsService(); }, token: FormUtilsService, providedIn: "root" });
