import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import {
  EventPublicControllerService, EventAdminControllerService,
  EventInfoResponse, APIResult, EventInfoRequest, APIResultboolean,
} from '@ent-regis/entregis-ts-angular';
import { APIResultEventInfoResponse } from '@ent-regis/entregis-ts-angular/model/aPIResultEventInfoResponse';
import { distinctUntilChanged, filter, first } from 'rxjs/operators';

@Injectable()
export class EventService implements OnDestroy {
  private subscription: Subscription;

  private eventAlias = new BehaviorSubject<string>(null);
  event = new BehaviorSubject<EventInfoResponse>(null);

  isTemplateCompleted = new BehaviorSubject<boolean>(null);
  isSlotCompleted = new BehaviorSubject<boolean>(null);
  isSeminarSlotCompleted = new BehaviorSubject<boolean>(null);

  constructor(
    private eventPublicControllerService: EventPublicControllerService,
    private eventAdminService: EventAdminControllerService,
  ) {
    this.subscription = new Subscription();
    this.subscription.add(this.eventAlias
      .pipe(distinctUntilChanged())
      .subscribe((alias: string) => {
        if (alias) {
          this.getEventByAlias(alias, (event: EventInfoResponse) => {
            this.changeEvent(event);
            this.fetchCheckCompletion()
          });
        }
      }));
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public columnToSortSymbol(column: string) {
    switch (column) {
      case 'eventId':
        return 'eventId';
      case 'eventName':
        return 'eventName';
      case 'eventAliasName':
        return 'eventAliasName';
    }
  }

  changeEventAlias(alias: string) {
    this.eventAlias.next(alias);
  }

  fetchEventInfo() {
    this.eventAlias.pipe(first(x => x != null)).subscribe((alias: string) => {
      this.getEventByAlias(alias, (event: EventInfoResponse) => {
        this.changeEvent(event);
      });
    });
  }

  fetchCheckCompletion() {
    this.event.pipe(first(x => x != null)).subscribe(res => {
      if (res) {
        this.checkTemplateCompletion(res.eventId, (isCompleted: boolean) => {
          this.isTemplateCompleted.next(isCompleted)
        });
        this.checkSlotCompletion(res.eventId, (isCompleted: boolean) => {
          this.isSlotCompleted.next(isCompleted)
        });
        this.checkSeminarCompletion(res.eventId, (isCompleted: boolean) => {
          this.isSeminarSlotCompleted.next(isCompleted)
        });
      }
    })
  }

  updateEventInfo(eventId: number, request: EventInfoRequest, completion: () => void) {
    this.eventAdminService.updateEventInfoUsingPOST(eventId, request).subscribe(() => {
      if (completion) { completion(); }
    });
  }

  private changeEvent(event: EventInfoResponse) {
    this.event.next(event);
  }

  private getEventByAlias(alias: string, completion: (event: EventInfoResponse) => void) {
    this.eventPublicControllerService.getInfoUsingGET(alias)
      .subscribe((response: APIResultEventInfoResponse) => {
        if (completion) { completion(response.data); }
      });
  }

  private getEventInfoById(eventId: number, completion: (event: EventInfoResponse) => void) {
    this.eventAdminService.getEventByIdUsingGET(eventId).subscribe((res: APIResult) => {
      if (completion) { completion(res.data); }
    });
  }

  private checkTemplateCompletion(eventId: number, completion: (isCompleted: boolean) => void) {
    this.eventAdminService.checkTemplateCompletionUsingGET(eventId).subscribe((res: APIResultboolean) => {
      if (completion) { completion(res.data); }
    });
  }

  private checkSlotCompletion(eventId: number, completion: (isCompleted: boolean) => void) {
    this.eventAdminService.checkSlotCompletionUsingGET(eventId).subscribe((res: APIResultboolean) => {
      if (completion) { completion(res.data); }
    });
  }

  private checkSeminarCompletion(eventId: number, completion: (isCompleted: boolean) => void) {
    this.eventAdminService.checkSeminarSlotCompletionUsingGET(eventId).subscribe((res: APIResultboolean) => {
      if (completion) { completion(res.data); }
    });
  }

}

