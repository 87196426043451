export const appConst = {
  auth: {
    storageKey: 'auth',
    apiAuthorization: 'apiAuthorization'
  },
  emailPattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
  telPattern: /^(0|[1-9][0-9]*)$/,
  aliasPattern: /^[a-z0-9-_\-]+$/,
  companyTail: 'PUBLIC COMPANY LIMITED'
};
