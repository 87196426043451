import { Component, OnInit } from '@angular/core';
import { InvestorCompanyResponse } from '@ent-regis/entregis-ts-angular';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InvestorCompanyService } from 'src/app/shared/services/investor-company.service';

@Component({
  selector: 'app-merge-investor-company-modal',
  templateUrl: './merge-investor-company-modal.component.html',
  styleUrls: ['./merge-investor-company-modal.component.scss']
})
export class MergeInvestorCompanyModalComponent implements OnInit {

  fromInvestorCompany: InvestorCompanyResponse;
  toInvestorCompany: any;

  constructor(
    public bsModalRef: BsModalRef,
    private investorCompanyService: InvestorCompanyService
  ) { }

  ngOnInit() {}

  confirm() {
    this.investorCompanyService.mergeInvestorCompany(
      this.fromInvestorCompany.investorCompanyId, this.toInvestorCompany.investorCompanyId, () => {
      this.investorCompanyService.fetchInvestorCompanies();
      this.investorCompanyService.fetchNeedApprove();
      this.bsModalRef.hide();
    });
  }

  cancel() {
    this.bsModalRef.hide();
  }

}
