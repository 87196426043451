import { EntRegisAuthService } from './../shared/services/ent-regis-auth.service';
import { Router } from '@angular/router';
import { Adal8Service } from 'adal-angular8';
import { Component, OnInit } from '@angular/core';
import { Configuration } from '@ent-regis/entregis-ts-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private adalService: Adal8Service,
    private router: Router,
    private entRegisAuthService: EntRegisAuthService,
    private apiConfiguration: Configuration
  ) { }

  async ngOnInit() {

    if (this.adalService.userInfo.authenticated) {
      const isAuthenticated = this.entRegisAuthService.isAuthenticated;
      if (typeof isAuthenticated === 'boolean') {
        this.decide(isAuthenticated);
      } else {
        const isAuthen = await isAuthenticated.toPromise();
        this.decide(isAuthen);
      }
    } else {
      // do nothing
    }
  }

  private async decide(isAuthen: boolean) {
    if (isAuthen) {
      this.goInside();
    } else {
      const response = await this.entRegisAuthService.getTokenWithAzureToken(this.adalService.getToken()).toPromise();
      const accessToken: string = response.access_token;
      const refreshToken: string = response.refresh_token;
      this.entRegisAuthService.updateToken(accessToken, refreshToken);
      this.goInside();
    }
  }

  login() {
    this.adalService.login();
  }

  goInside() {
    const comeBackTo = localStorage.getItem('comeBackTo');

    if (comeBackTo) {
      localStorage.removeItem('comeBackTo');
      window.location.href = comeBackTo;
    } else {
      this.router.navigate(['/']);
    }
  }
}
