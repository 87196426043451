import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import { EventAdminControllerService, EventResponse } from '@ent-regis/entregis-ts-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIResultEventPageResponse } from '@ent-regis/entregis-ts-angular/model/aPIResultEventPageResponse';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

  public dataSource = new EventDataSource();
  public PAGE_SIZE = 50;
  public totalElements = 0;
  public displayedColumns: string[] = ['eventId', 'eventName', 'eventAliasName', 'eventPeriod'];
  public page = 0;
  private dataSize = 0;
  private sort: Sort;

  constructor(
    private router: Router,
    public nav: NavbarService,
    private eventAdminService: EventAdminControllerService,
  ) { }

  public ngOnInit(): void {
    this.nav.show();
    this.search();
  }

  public search(): void {
    this.eventAdminService.getEventListUsingGET(this.page,
      this.PAGE_SIZE,
      this.sort == null ||
        this.sort.direction == null ||
        this.sort.active == null ||
        this.sort.direction === undefined ||
        this.sort.active === undefined ||
        this.sort.direction === '' ||
        this.sort.active === '' ?
        [this.columnToSortSymbol('createDate') + ',asc'] :
        [this.columnToSortSymbol(this.sort.active) + ',' + this.sort.direction]).subscribe((result: APIResultEventPageResponse) => {
          this.totalElements = result.data.totalElement;
          this.dataSize = result.data.events.length;
          this.dataSource.update(result.data.events);
        });
  }

  public columnToSortSymbol(column: string) {
    switch (column) {
      case 'eventName':
        return 'eventName';
      case '"eventAliasName"':
        return 'eventAlias';
      case 'createDate':
        return 'createdDate';
    }
  }

  public pageSizeChange(): void {
    this.page = 0;
    this.search();
  }

  public onSortChange(sort: Sort): void {
    this.sort = sort;
    this.search();
  }

  public pageChange(): void {
    this.search();
  }

  public addEvent(): void {
    this.router.navigate(['regis/event/create']);
  }

  getFirstElementOfPage() : number {
    let firstElement = 0;
    if (this.totalElements > 0) {
      firstElement = this.totalElements > this.PAGE_SIZE ? (this.page * this.PAGE_SIZE) + 1 : 1;
    }
    return firstElement;
  }

  getLastElementOfPage() : number {
    return this.totalElements > this.PAGE_SIZE ? (this.page * this.PAGE_SIZE) + this.dataSize : this.dataSize;
  }

}

class EventDataSource extends DataSource<EventResponse> {
  data = new BehaviorSubject<EventResponse[]>([]);

  connect(): Observable<EventResponse[]> {
    return this.data;
  }

  disconnect() {

  }

  update(x: EventResponse[]) {
    this.data.next(x);
  }
}
