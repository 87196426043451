/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merge-investor-company-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./merge-investor-company-modal.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "../../../shared/services/investor-company.service";
var styles_MergeInvestorCompanyModalComponent = [i0.styles];
var RenderType_MergeInvestorCompanyModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MergeInvestorCompanyModalComponent, data: {} });
export { RenderType_MergeInvestorCompanyModalComponent as RenderType_MergeInvestorCompanyModalComponent };
export function View_MergeInvestorCompanyModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "modal-body text-center"], ["id", "merge-investor-company-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "title"], ["id", "merge-investor-company-title-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["MERGE"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "p", [["id", "merge-investor-company-content-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ARE YOU SURE WANT TO MERGE "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" to "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" ?"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-secondary mt-1"], ["id", "merge-investor-company-cancel-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CANCEL"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-primary ml-2 mt-1"], ["id", "merge-investor-company-confirm-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CONFIRM"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fromInvestorCompany.companyName; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.toInvestorCompany.companyName; _ck(_v, 10, 0, currVal_1); }); }
export function View_MergeInvestorCompanyModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merge-investor-company-modal", [], null, null, null, View_MergeInvestorCompanyModalComponent_0, RenderType_MergeInvestorCompanyModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.MergeInvestorCompanyModalComponent, [i3.BsModalRef, i4.InvestorCompanyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MergeInvestorCompanyModalComponentNgFactory = i1.ɵccf("app-merge-investor-company-modal", i2.MergeInvestorCompanyModalComponent, View_MergeInvestorCompanyModalComponent_Host_0, {}, {}, []);
export { MergeInvestorCompanyModalComponentNgFactory as MergeInvestorCompanyModalComponentNgFactory };
