import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators/catchError';
import { of, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router
  ) { }

  private handleAuthError(req: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      const errorMessage = err.error != null ? err.error.message : err.message;
      console.error(errorMessage);
      this.router.navigateByUrl('/login');
      return of(null);
    } else {
      return throwError(err);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => this.handleAuthError(req, next, err))
    );
  }
}
