import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.scss']
})
export class CustomPaginatorComponent implements OnInit {
  currentPage = 0;
  maxSize = 10;
  @Input() pageSize: number;
  @Input() count: number;
  @Input() page = 0;
  @Output() pageChange = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.currentPage = this.page + 1;
  }

  onPageChange(event: number): void {
    this.pageChange.emit(event - 1);
  }
}