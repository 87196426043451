import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Adal8Service } from 'adal-angular8';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  faUser = faUser;

  name: string;

  constructor(
    private adalService: Adal8Service
  ) { }

  ngOnInit() {
    this.name = this.adalService.userInfo.profile.name;
  }

  logout() {
    this.adalService.logOut();
  }
}
