import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScheduleCompanyResponse, SendScheduleControllerService } from '@ent-regis/entregis-ts-angular';

@Component({
  selector: 'app-company-schedule-print',
  templateUrl: './company-schedule-print.component.html',
  styleUrls: ['./company-schedule-print.component.scss']
})
export class CompanySchedulePrintComponent implements OnInit {
  public scheduleData: ScheduleCompanyResponse;
  public eventTypeEnum = ScheduleCompanyResponse.EventTypeEnum;
  private participantId: number;
  constructor(
    private route: ActivatedRoute,
    private scheduleService: SendScheduleControllerService
  ) { }

  ngOnInit() {
    this.participantId = +this.route.snapshot.paramMap.get('participantId');
    this.scheduleService.getScheduleCompanyInfoUsingGET1(this.participantId)
    .subscribe(result => {
      this.scheduleData = result.data;
      setTimeout(() => {
        window.print();
        window.close();
      }, 2000)
    });
  }

}
