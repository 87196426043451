import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebExAdminControllerService, WebExUserResponse } from '@ent-regis/entregis-ts-angular';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-web-ex',
  templateUrl: './web-ex.component.html',
  styleUrls: ['./web-ex.component.scss']
})
export class WebExComponent implements OnInit {
  public faCopy = faCopy;
  public listWebEx:WebExUserResponse[] = [];
  constructor(
    private WebExAdminService: WebExAdminControllerService
  ) { }

  ngOnInit() {
    this.WebExAdminService.getAllActiveUserUsingGET().subscribe((res)=>{
      this.listWebEx = res.data;
    })
  }

  connectWebEx(item:WebExUserResponse): void {
    const redirect_uri = `${environment.baseUrl}/public/webex/authorization`;
    window.open(`https://webexapis.com/v1/authorize?client_id=${item.clientId}&response_type=code&redirect_uri=${redirect_uri}&scope=meeting%3Arecordings_read%20meeting%3Aadmin_preferences_write%20meeting%3Aadmin_schedule_write%20meeting%3Aadmin_preferences_read%20meeting%3Aschedules_read%20meeting%3Aparticipants_read%20meeting%3Aadmin_participants_read%20meeting%3Apreferences_write%20meeting%3Arecordings_write%20meeting%3Apreferences_read%20meeting%3Aadmin_recordings_read%20meeting%3Atranscripts_read%20meeting%3Aschedules_write%20spark%3Akms%20meeting%3Acontrols_write%20meeting%3Aadmin_recordings_write%20meeting%3Acontrols_read%20meeting%3Aparticipants_write%20meeting%3Aadmin_config_write%20meeting%3Aadmin_config_read%20meeting%3Aadmin_schedule_read%20meeting%3Aadmin_transcripts_read&&state=${item.state}`)
  }

  onClipboard(event: string) {
    const el = document.createElement('textarea');
    el.value = event;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

}
