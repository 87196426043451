/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "footer", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "container-custom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "p", [["class", "clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "span", [["class", "float-md-left d-block d-md-inline-block"], ["id", "copyright-text"], ["style", "font-size: 0.8rem;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Copyright \u00A9 The Stock Exchange of Thailand. "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "text-bold-800 grey darken-2"], ["href", "https://www.set.or.th/en/privacy-policy.html"], ["id", "privacy-policy-link"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Privacy Policy "])), (_l()(), i1.ɵted(-1, null, [" The contents contained in this Web Site are provided for informative and educational purpose only. SET does not make any representations and hereby disclaims with respect to this Web Site. Retrieving any information indicates your acceptance of the "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "text-bold-800 grey darken-2"], ["href", "https://www.set.or.th/en/terms-and-conditions.html"], ["id", "terms-and-conditions-link"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms & Conditions of Use "]))], null, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
