<app-header></app-header>
<div *ngIf="isVisibleNavbar">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a id="redirect-event-link" class="nav-link" [routerLink]="'event'" routerLinkActive="active">EVENT</a>
    </li>
    <li class="nav-item">
      <a id="redirect-investor-company-link" class="nav-link" [routerLink]="'investor-company'" routerLinkActive="active">
        <span id="investor-company-text">INVESTOR COMPANY</span>
        <span id="company-amount-text" class="badge" *ngIf="needApprove && companiesCount !== 0">{{companiesCount}}</span>
      </a>
    </li>
    <li class="nav-item">
      <a id="redirect-investor-company-link" class="nav-link" [routerLink]="'web-ex'" routerLinkActive="active">
        <span id="investor-company-text">WEB-EX</span>
      </a>
    </li>
  </ul>
</div>
<div class="content">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
