<div class="container-custom mt-3">
    <div>
        <button id="event-add-button" class="btn btn-secondary" (click)=addEvent()>ADD EVENT</button>
    </div>
    <table id="event-table" matSort class="table table-hover mt-3" cdk-table [dataSource]="dataSource"
        (matSortChange)=onSortChange($event)>

        <ng-container cdkColumnDef="eventId">
            <th id="event-id-column" class="table-header-cell" cdk-header-cell *cdkHeaderCellDef> ID </th>
            <td id="event-id-text-{{element.eventId}}" class="table-cell" cdk-cell *cdkCellDef="let element">
                {{element.eventId}} </td>
        </ng-container>

        <ng-container cdkColumnDef="eventName">
            <th id="event-name-column-" class="table-header-cell" cdk-header-cell *cdkHeaderCellDef mat-sort-header> NAME </th>
            <td id="event-name-text-{{element.eventId}}" class="table-cell" cdk-cell *cdkCellDef="let element"> {{element.eventName}}</td>
        </ng-container>

        <ng-container cdkColumnDef="eventAliasName">
            <th id="event-alias-name-column" class="table-header-cell" cdk-header-cell *cdkHeaderCellDef mat-sort-header> EVENT ALIAS </th>
            <td id="event-alias-name-text-{{element.eventId}}" class="table-cell" cdk-cell *cdkCellDef="let element"> {{element.eventAliasName}} </td>
        </ng-container>

        <ng-container cdkColumnDef="eventPeriod">
            <th id="event-alias-name-column" class="table-header-cell" cdk-header-cell *cdkHeaderCellDef> PERIOD </th>
            <td id="event-period-text-{{element.eventId}}" class="table-cell" cdk-cell *cdkCellDef="let element"> {{element.startDate | date: 'dd MMM yyyy'}} - {{element.endDate | date: 'dd MMM yyyy'}} </td>
        </ng-container>

        <tr class="table-header" cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr id="event-redirect-detail-link" class="table-row" cdk-row *cdkRowDef="let row; columns: displayedColumns;"
            [routerLink]="row.eventAliasName"></tr>
    </table>
    <div id="event-not-found-text" class="not-found" *ngIf="totalElements === 0">No event found.
    </div>
    <div id="event-pagination" class="mt-2">
        <div class="mt-2 d-flex align-items-center">
            <div>
                <select name="page" id="page" [(ngModel)]=PAGE_SIZE (change)="pageSizeChange()" class="form-control">
                    <option value=10>10</option>
                    <option value=20>20</option>
                    <option value=50>50</option>
                    <option value=100>100</option>
                </select>
            </div>
            <span class="px-2">
                Showing {{ getFirstElementOfPage() }} - {{ getLastElementOfPage() }} of {{totalElements}} records
            </span>
            <div class="ml-auto">
                <app-custom-paginator [pageSize]=PAGE_SIZE [count]=totalElements [(page)]=page (pageChange)=pageChange()>
                </app-custom-paginator>
            </div>
        </div>
    </div>
</div>
