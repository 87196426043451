import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators/catchError';
import { of, throwError } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';

@Injectable()
export class GeneralAPIHandleInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private alert: AlertService
    ) { }

    private handleAuthError(req: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<any> {
        if (err.status === 500) {
            const errorMessage = err.error != null ? err.error.message : err.message;
            console.log(errorMessage);
            console.log(err.error);
            this.alert.showError(errorMessage);
           // alert('Internal Error');
            return of(null);
        } else {
            return throwError(err);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => this.handleAuthError(req, next, err))
        );
    }
}
