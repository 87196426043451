import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArrayPipe } from './form-array.pipe';
import { FormControlPipe } from './form-control.pipe';
import { FormGroupPipe } from './form-group.pipe';
import { MeetingTypePipe } from './meeting-type.pipe';
import { CamelToSpaceAndUppercasePipe } from './camel-to-space-and-uppercase.pipe';
import { AttendeeTypePipe } from './attendee-type.pipe';
import { RoomTypePipe } from '../../pipes/room-type.pip';



@NgModule({
  declarations: [
    FormArrayPipe,
    FormControlPipe,
    FormGroupPipe,
    MeetingTypePipe,
    RoomTypePipe,
    AttendeeTypePipe,
    CamelToSpaceAndUppercasePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FormArrayPipe,
    FormControlPipe,
    FormGroupPipe,
    MeetingTypePipe,
    RoomTypePipe,
    AttendeeTypePipe,
    CamelToSpaceAndUppercasePipe
  ]
})
export class PipesModule { }
