/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./header.component";
import * as i7 from "adal-angular8/adal8.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "nav", [["class", "navbar regis-header"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgbNavbar, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "navbar-brand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["height", "40"], ["id", "set-logo-image"], ["src", "/assets/images/logo_set_50.png"], ["width", "auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["id", "system-name-text"], ["style", "color: black; font-size: larger; vertical-align: middle;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0Enterprise Registration (SET Admin)"])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "btn btn-lean"], ["id", "username-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i3.View_FaIconComponent_0, i3.RenderType_FaIconComponent)), i1.ɵdid(9, 573440, null, 0, i4.FaIconComponent, [i5.DomSanitizer, i4.FaConfig, i4.FaIconLibrary, [2, i4.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵted(10, null, [" ", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-lean"], ["id", "logout-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faUser; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 9).title; var currVal_1 = i1.ɵnov(_v, 9).renderedIconHTML; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_3 = _co.name; _ck(_v, 10, 0, currVal_3); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i6.HeaderComponent, [i7.Adal8Service], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i6.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
