import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
export class AlertService {
    constructor() { }
    showSuccess(text) {
        return Swal.fire({
            confirmButtonColor: '#fbaf47',
            heightAuto: false,
            text: text,
            icon: 'success',
            showClass: {
                popup: 'animate__animated animate__fadeIn animate__faster'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOut animate__faster'
            }
        });
    }
    showError(text) {
        return Swal.fire({
            heightAuto: false,
            confirmButtonColor: '#fbaf47',
            text: text,
            icon: 'error',
            showClass: {
                popup: 'animate__animated animate__fadeIn animate__faster'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOut animate__faster'
            }
        });
    }
    showInfo(title, message, showCancel = false) {
        return Swal.fire({
            confirmButtonColor: '#fbaf47',
            heightAuto: false,
            title: title,
            showCancelButton: showCancel,
            text: message,
            showClass: {
                popup: 'animate__animated animate__fadeIn animate__faster'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOut animate__faster'
            }
        });
    }
}
AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(); }, token: AlertService, providedIn: "root" });
