import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { InvestorCompanyAdminResponse } from '@ent-regis/entregis-ts-angular';
import { InvestorCompanyService } from '../shared/services/investor-company.service';
import { NavbarService } from '../shared/services/navbar.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterContentChecked {
  isVisibleNavbar = true;
  needApprove = false;
  companies: InvestorCompanyAdminResponse[];
  companiesCount = 0;

  constructor(
    public nav: NavbarService,
    private investorCompanyService: InvestorCompanyService
  ) { }

  ngOnInit() {
    this.nav.show();
    this.getNeedApprove();
    this.getCompaniesCount();
  }

  ngAfterContentChecked() {
    if (this.isVisibleNavbar !== this.nav.visible) {
      this.isVisibleNavbar = this.nav.visible;
    }
  }

  getNeedApprove() {
    this.investorCompanyService.needApprove.subscribe(data => {
      this.needApprove = data.needApprove;
    });
  }

  getCompaniesCount() {
    this.investorCompanyService.investorCompanies.subscribe(data => {
      this.companiesCount = 0;
      data.forEach(element => {
        if (!element.isApproved) {
          this.companiesCount = this.companiesCount + 1;
        }
      });
    });
  }
}
