import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Configuration } from '@ent-regis/entregis-ts-angular';
import { accessTokenKey, refreshTokenKey } from 'src/app/config/app-constant';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntRegisAuthService {

  refreshTokenInProgress = false;
  accessTokenSubject = new BehaviorSubject<string>(null);
  refreshTokenSubject = new BehaviorSubject<string>(null);

  get token(): string {
    return localStorage.getItem(accessTokenKey);
  }

  get refreshToken(): string {
    return localStorage.getItem(refreshTokenKey);
  }

  constructor(
    private apiConfig: Configuration,
    private httpClient: HttpClient
  ) {
    this.accessTokenSubject.next(this.token);
    this.refreshTokenSubject.next(this.refreshToken);
  }

  doesTokenExpired(): boolean {
    return this.token == null
      ? true
      : (jwt_decode(this.token) as any).exp < Date.now() / 1000;
  }

  doesRefreshTokenExpired(): boolean {
    return this.refreshToken == null
      ? true
      : (jwt_decode(this.refreshToken) as any).exp < Date.now() / 1000;
  }

  get isAuthenticated(): Observable<boolean> | boolean {
    if (this.token === null || this.token === undefined) {
      return false;
    } else if (this.doesTokenExpired()) {
      if (this.doesRefreshTokenExpired()) {
        return false;
      } else {
        return this.refresh();
      }
    } else {
      return true;
    }
  }

  getUsername(): string {
    return this.token == null ? null : (jwt_decode(this.token) as any).user_name;
  }

  getRoles(): string[] {
    return this.token == null ? null : (jwt_decode(this.token) as any).authorities;
  }

  updateToken(token: string, refreshToken: string) {
    this.accessTokenSubject.next(token);
    this.refreshTokenSubject.next(refreshToken);

    localStorage.setItem(accessTokenKey, token);
    localStorage.setItem(refreshTokenKey, refreshToken);

    this.updateSwaggerToken();
  }

  updateSwaggerToken() {
    this.apiConfig.apiKeys.Authorization = 'Bearer ' + this.token;
  }

  refresh() {
    console.log('refresh token');

    this.refreshTokenSubject.next(null);
    this.accessTokenSubject.next(null);
    this.refreshTokenInProgress = true;

    return this.getTokenWithRefresh(this.refreshToken)
      .pipe(
        map((x: any) => {
          const accessToken: string = x.access_token;
          const refreshToken: string = x.refresh_token;

          this.updateToken(accessToken, refreshToken);

          this.refreshTokenInProgress = false;
          return true;
        }),
        catchError(() => of(false))
      );
  }

  getTokenWithAzureToken(azureToken: string): Observable<any> {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('azure_token', azureToken);
    return this.httpClient.post(environment.baseUrl + '/oauth/token', formData, {
      headers: {
        Authorization: 'Basic ZW50LXJlZ2lzOmVudC1yZWdpcy1zZWNyZXQ='
      }
    });
  }

  getTokenWithRefresh(refreshToken: string): Observable<any> {
    const formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);
    return this.httpClient.post(environment.baseUrl + '/oauth/token', formData, {
      headers: {
        Authorization: 'Basic ZW50LXJlZ2lzOmVudC1yZWdpcy1zZWNyZXQ='
      }
    });
  }
}
