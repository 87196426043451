import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormUtilsService {

  constructor() { }

  setError(form: AbstractControl, errorName: string, errorValue: any) {
    var error = form.errors;

    if(error == null) {
      error = {};
    }
    error[errorName] = errorValue;

    form.setErrors(error);  
  }

  removeError(form: AbstractControl, errorName: string) {
    var error = form.errors;

    if (error) {
      delete error[errorName];
      if (Object.keys(error).length == 0) {
        error = null;
      }
  
      form.setErrors(error);  
    }
  }

}
