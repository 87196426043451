import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss']
})
export class EventInfoComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() { }

  onSubmit(): void {
    this.router.navigate(['/regis/event']);
  }

}
