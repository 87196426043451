import { config } from './../environments/config';
import { Component } from '@angular/core';
import { Adal8Service } from 'adal-angular8';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'enterprise-registration-web-admin';

  constructor(private adalService: Adal8Service) {
    this.adalService.init(config.adalConfig);
    this.adalService.handleWindowCallback();
  }
}
