import { GeneralAPIHandleInterceptor } from './core/interceptors/general-api-handle-interceptor';
import { AuthInterceptor } from './core/authentication/auth-interceptor';
import { AuthProvider } from './core/authentication/auth-provider';
import { config } from './../environments/config';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { RouterModule } from '@angular/router';
import {
  Configuration,
  EventPublicControllerService,
  BASE_PATH,
  ParticipantControllerService,
  DietaryPublicControllerService,
  CompanySlotControllerService,
  RegisterCompanyAdminControllerService,
  RegisterCompanyActivityLogAdminControllerService,
  SlotAdminControllerService,
  ParticipantAdminControllerService,
  CompanySlotAdminControllerService,
  RegisterInvestorAdminControllerService,
  BrokerPublicControllerService,
  CountryPublicControllerService,
  EventAdminControllerService,
  InvestorMeetingRequestAdminControllerService,
  HotelAdminControllerService,
  InvestorSlotAdminControllerService,
  RegisterInvestorPublicControllerService,
  MeetingRoomAdminControllerService, InvestorCompanyAdminControllerService, InvestorMatchingAdminControllerService, ConfigurationParameters, ApiModule
} from '@ent-regis/entregis-ts-angular';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CdkTableModule } from '@angular/cdk/table';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule, DatePipe } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { loaderConfig } from './config/loader-config';
import localeTh from '@angular/common/locales/th';
import { registerLocaleData } from '@angular/common';
import { Adal8HTTPService, Adal8Service } from 'adal-angular8';
import { LogoutComponent } from './logout/logout.component';
import { MatSortModule, MatDividerModule, MatToolbarModule, MatButtonModule, MatIconModule, MatListModule, MatSidenavModule, } from '@angular/material';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NavbarService } from './shared/services/navbar.service';
import {
  ApproveInvestorCompanyModalComponent
} from './layout/investor-company/approve-investor-company-modal/approve-investor-company-modal.component';
import { MergeInvestorCompanyModalComponent } from './layout/investor-company/merge-investor-company-modal/merge-investor-company-modal.component';
import { InvestorCompanyComponent } from './layout/investor-company/investor-company.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { CKEditorModule } from 'ckeditor4-angular';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { PipesModule } from './shared/modules/pipes/pipes.module';
import { TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { EventsComponent } from './layout/events/events.component';
import { EventListComponent } from './layout/events/event-list/event-list.component';
import { EventInfoComponent } from './layout/events/event-info/event-info.component';
import { SharedComponentsModule } from './shared/shared-components/shared-components.module';
import { PrintsComponent } from './layout/prints/prints.component';
import { InvestorSchedulePrintComponent } from './layout/prints/investor-schedule-print/investor-schedule-print.component';
import { CompanySchedulePrintComponent } from './layout/prints/company-schedule-print/company-schedule-print.component';
import { DirectivesModule } from './shared/modules/directives/directive.module';
import { WebExComponent } from './layout/web-ex/web-ex.component';

registerLocaleData(localeTh);

@NgModule({
  entryComponents: [
    ApproveInvestorCompanyModalComponent, // refactor
    MergeInvestorCompanyModalComponent, // refactor
  ],
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    EventsComponent,
    EventListComponent,
    LoginComponent,
    EventInfoComponent,
    LogoutComponent,
    InvestorCompanyComponent,
    ApproveInvestorCompanyModalComponent,
    MergeInvestorCompanyModalComponent,
    PrintsComponent,
    InvestorSchedulePrintComponent,
    CompanySchedulePrintComponent,
    WebExComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    NgSelectModule,
    CdkTableModule,
    MatSortModule,
    HttpClientModule,
    FontAwesomeModule,
    RouterModule,
    NgbPaginationModule,
    MatDividerModule,
    CommonModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    NgxUiLoaderModule.forRoot(loaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true, excludeRegexp: ['api/v1/public/register-investor-profile\?','/api/admin/meeting-room/on-process-generate-webex-link\?'] }),
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    ApiModule.forRoot(apiConfigFactory),
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    Ng2ImgMaxModule,
    CKEditorModule,
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    UiSwitchModule,
    PipesModule,
    DirectivesModule,
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    SharedComponentsModule
  ],
  providers: [
    AuthProvider,
    RegisterCompanyAdminControllerService,
    EventPublicControllerService,
    ParticipantControllerService,
    DietaryPublicControllerService,
    RegisterCompanyActivityLogAdminControllerService,
    SlotAdminControllerService,
    CompanySlotControllerService,
    ParticipantAdminControllerService,
    CompanySlotAdminControllerService,
    RegisterInvestorAdminControllerService,
    DatePipe,
    BrokerPublicControllerService,
    CountryPublicControllerService,
    EventAdminControllerService,
    InvestorMeetingRequestAdminControllerService,
    HotelAdminControllerService,
    InvestorSlotAdminControllerService,
    InvestorMatchingAdminControllerService,
    RegisterInvestorPublicControllerService,
    MeetingRoomAdminControllerService,
    NavbarService,
    InvestorCompanyAdminControllerService,
    {
      provide: BASE_PATH, useValue: config.apiEndPoint
    },
    Adal8Service,
    {
      provide: Adal8HTTPService, useFactory: Adal8HTTPService.factory, deps: [HttpClient, Adal8Service]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      multi: true,
      deps: [AuthProvider]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GeneralAPIHandleInterceptor,
      multi: true,
    },
    AuthGuardService,
    NgxUiLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

let sharedApiModuleConfig: Configuration;

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
  };

  params.apiKeys = {
    Authorization: ''
  };

  if (sharedApiModuleConfig == null) {
    sharedApiModuleConfig = new Configuration(params);
  }
  return sharedApiModuleConfig;
}

export function init_app(authProvider: AuthProvider) {
  return () => authProvider.onAppLoaded();
}
