import { Pipe, PipeTransform } from '@angular/core';
import { AttendeeType } from 'src/app/constants/attendee-type';

@Pipe({
  name: 'attendeeType'
})
export class AttendeeTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const result = AttendeeType.find(f => f.type === value);
      return result ? result.label : null;
    } else {
      return null;
    }
  }

}
