export const environment = {
  production: true,
  baseUrl: 'https://testmgt-entregis.set.or.th/api',
  redirectUrl: 'https://testmgt-entregis.set.or.th/login',
  tenant: '7cf70668-de90-4e0a-bdc1-42dd27fc4aee',
  clientId: '07e996e4-9a5f-408f-9f32-43475f33e813',
  publicUrl: 'https://test-entregis.set.or.th',
  adminUrl: 'https://testmgt-entregis.set.or.th',
  webExClientId: 'C86ab38b90f84a1d8aaf96d2db5e1f649e8cf77c2edbb23f478a979bf7f3b1d96',
};
