import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
export class PrinterService {
    constructor() {
        this.qzConnectStatus = new BehaviorSubject('NOT_CONNECT');
        qz.security.setCertificatePromise((resolve, reject) => {
            fetch(environment.baseUrl + "/public/printer/qz-tray/cert", { cache: 'no-store', headers: { 'Content-Type': 'text/plain' } })
                .then(function (result) {
                result.ok ? result.json().then(r => resolve(r.data)) : reject(result.text());
            });
        });
        qz.security.setSignatureAlgorithm("SHA512");
        qz.security.setSignaturePromise(function (toSign) {
            return function (resolve, reject) {
                fetch(environment.baseUrl + "/public/printer/qz-tray/signing?request=" + toSign, { cache: 'no-store', headers: { 'Content-Type': 'application/json' } })
                    .then(function (result) {
                    result.ok ? result.json().then(r => resolve(r.data)) : reject(result.text());
                });
            };
        });
    }
    connectQZTray() {
        if (!qz.websocket.isActive()) {
            this.qzConnectStatus.next('CONNECTING');
            return qz.websocket.connect()
                .then(() => { this.qzConnectStatus.next('CONNECTED'); });
        }
        else {
            return Promise.resolve();
        }
    }
    getDefaultPrinterName() {
        return this.connectQZTray().then(() => {
            return qz.printers.getDefault().then((data) => {
                return data;
            });
        });
    }
    print(printerName, zpl) {
        const config = qz.configs.create(printerName);
        const data = [
            zpl
        ];
        qz.print(config, data).catch((e) => { console.error(e); });
    }
}
PrinterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrinterService_Factory() { return new PrinterService(); }, token: PrinterService, providedIn: "root" });
