import { Component, OnInit, ViewChild } from '@angular/core';
import { InvestorCompanyAdminControllerService, InvestorCompanyAdminResponse } from '@ent-regis/entregis-ts-angular';
import { faCheck, faEdit, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest } from 'rxjs';
import { InvestorCompanyService } from 'src/app/shared/services/investor-company.service';
import { ApproveInvestorCompanyModalComponent } from './approve-investor-company-modal/approve-investor-company-modal.component';
import { MergeInvestorCompanyModalComponent } from './merge-investor-company-modal/merge-investor-company-modal.component';
import { ConfirmModalComponent } from 'src/app/shared/shared-components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-investor-company',
  templateUrl: './investor-company.component.html',
  styleUrls: ['./investor-company.component.scss']
})
export class InvestorCompanyComponent implements OnInit {

  companies: InvestorCompanyAdminResponse[];
  companiesDropdown = [];
  public faEdit = faEdit;
  public faTimes = faTimes;
  public faCheck = faCheck;
  public faEyeSlash = faEyeSlash;
  public modes: InvestorCompanyMode[] = [];
  public nameEdit: string[] = [];
  public InvestorCompanyMode = InvestorCompanyMode;

  approveModalRef: BsModalRef;
  mergeModalRef: BsModalRef;
  @ViewChild('confirmModal', { static: false }) confirmModal: ConfirmModalComponent;

  constructor(
    private investorCompanyService: InvestorCompanyService,
    private investorCompanyAdminService: InvestorCompanyAdminControllerService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.investorCompanyService.fetchInvestorCompanies();
    combineLatest(
      this.investorCompanyService.investorCompanies
    ).subscribe(
      (result: [InvestorCompanyAdminResponse[]]) => {
        const [companies] = result;
        this.companies = companies;
        this.sortData();
        this.getAllApprovedCompany();
        this.modes = companies.map(_ => InvestorCompanyMode.idle);
        this.nameEdit = companies.map(company => company.companyName);
      });
  }

  sortData() {
    this.companies.sort((a, b) => {
      if (a.isApproved === b.isApproved) {
        return 0;
      }
      if (a.isApproved) {
        return 1;
      }
      if (b.isApproved) {
        return -1;
      }
    });
  }

  getAllApprovedCompany() {
    this.companies.forEach(data => {
      if (data.isApproved) {
        this.companiesDropdown.push(data);
      }
    });
  }

  onSelected(selectCompanyId: any, newCompany: InvestorCompanyAdminResponse) {
    if (selectCompanyId === 'approve') {
      this.approveModalRef = this.modalService.show(ApproveInvestorCompanyModalComponent, {
        initialState: {
          newInvestorCompany: newCompany
        }
      });
    } else if (selectCompanyId !== undefined) {
      this.mergeModalRef = this.modalService.show(MergeInvestorCompanyModalComponent, {
        initialState: {
          fromInvestorCompany: newCompany,
          toInvestorCompany: this.companiesDropdown.find(c => c.investorCompanyId === selectCompanyId)
        }
      });
    }
  }

  updateCompany(investorCompanyId: number, name: string, index: number) {
    this.investorCompanyAdminService.updateInvestorCompanyUsingPOST(investorCompanyId, { name: name }).subscribe(_ => {
      this.investorCompanyService.fetchAllData();
    })
  }

  hideCompany(company: InvestorCompanyAdminResponse): void {
    this.confirmModal.title = 'Confirm Hide ';
    this.confirmModal.message = `You want to hide '${company.companyName}'.`;
    this.confirmModal.btnConfirm = 'Hided';
    this.confirmModal.openModal();

    this.confirmModal.confirm = () => {
      this.investorCompanyAdminService.hideInvestorCompanyUsingPOST(company.investorCompanyId).subscribe(_ => {
        this.investorCompanyService.fetchAllData();
        this.confirmModal.onClose();
      });
    };
  }
}

enum InvestorCompanyMode {
  idle, edit
}