/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./print-badge-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../modules/pipes/attendee-type.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./print-badge-modal.component";
import * as i5 from "ngx-bootstrap/modal";
import * as i6 from "@ent-regis/entregis-ts-angular/api/checkInController.service";
import * as i7 from "../../services/printer.service";
var styles_PrintBadgeModalComponent = [i0.styles];
var RenderType_PrintBadgeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrintBadgeModalComponent, data: {} });
export { RenderType_PrintBadgeModalComponent as RenderType_PrintBadgeModalComponent };
export function View_PrintBadgeModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.AttendeeTypePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "bg-set h-100 w-100 d-flex flex-column justify-content-around"], ["id", "print-badge-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [["class", "card"], ["style", "width: 30rem;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 17, "div", [["class", "card-body text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "mt-3"], ["id", "print-badge-name-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h6", [["class", "text-muted"], ["id", "print-badge-company-name-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "text-center mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-lg btn-secondary mr-2"], ["id", "print-badge-cancel-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-lg btn-primary text-white px-3"], ["id", "print-badge-print-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.print() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Print"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "p", [["class", "text-danger"], ["id", "print-badge-error-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 5, "span", [["class", "float-left mt-2 p-1 rounded-pill"], ["id", "print-badge-attendee-type-text"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(18, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(19, { "background-color": 0 }), (_l()(), i1.ɵted(20, null, ["Attendee type: ", ""])), i1.ɵppd(21, 1)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _ck(_v, 19, 0, _co.color); _ck(_v, 18, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.firstName; var currVal_1 = _co.lastName; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = _co.companyName; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.alertText; _ck(_v, 15, 0, currVal_3); var currVal_5 = i1.ɵunv(_v, 20, 0, _ck(_v, 21, 0, i1.ɵnov(_v, 0), _co.attendeeType)); _ck(_v, 20, 0, currVal_5); }); }
export function View_PrintBadgeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-print-badge-modal", [], null, null, null, View_PrintBadgeModalComponent_0, RenderType_PrintBadgeModalComponent)), i1.ɵdid(1, 245760, null, 0, i4.PrintBadgeModalComponent, [i5.BsModalRef, i6.CheckInControllerService, i7.PrinterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrintBadgeModalComponentNgFactory = i1.ɵccf("app-print-badge-modal", i4.PrintBadgeModalComponent, View_PrintBadgeModalComponent_Host_0, {}, { success: "success" }, []);
export { PrintBadgeModalComponentNgFactory as PrintBadgeModalComponentNgFactory };
