import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SendScheduleControllerService } from '@ent-regis/entregis-ts-angular/api/sendScheduleController.service';
import { ScheduleInvestorResponse } from '@ent-regis/entregis-ts-angular/model/scheduleInvestorResponse';

@Component({
  selector: 'app-investor-schedule-print',
  templateUrl: './investor-schedule-print.component.html',
  styleUrls: ['./investor-schedule-print.component.scss']
})
export class InvestorSchedulePrintComponent implements OnInit {
  private registerInvestorId: number;
  public scheduleData: ScheduleInvestorResponse;
  public haveAtLeaseOnePresentation = false;
  public eventTypeEnum = ScheduleInvestorResponse.EventTypeEnum;

  constructor(
    private route: ActivatedRoute,
    private scheduleService: SendScheduleControllerService
  ) { }

  ngOnInit() {
    this.registerInvestorId = +this.route.snapshot.paramMap.get('investorId');
    this.scheduleService.getScheduleInvestorInfoUsingGET1(this.registerInvestorId)
    .subscribe( result => {
      this.scheduleData = result.data;
      this.checkPresentation(result.data);
      setTimeout(() => {
        window.print();
        window.close();
      }, 2000)
    });
  }

  checkPresentation(data: ScheduleInvestorResponse) {
    data.slots.forEach(slot => {
      slot.slotPeriods.forEach(p => {
        if (p.presentationLink) {
          this.haveAtLeaseOnePresentation = true;
        }
      })
    })
  }

}
