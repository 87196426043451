import { DataSource } from '@angular/cdk/collections';
import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export class EventListComponent {
    constructor(router, nav, eventAdminService) {
        this.router = router;
        this.nav = nav;
        this.eventAdminService = eventAdminService;
        this.dataSource = new EventDataSource();
        this.PAGE_SIZE = 50;
        this.totalElements = 0;
        this.displayedColumns = ['eventId', 'eventName', 'eventAliasName', 'eventPeriod'];
        this.page = 0;
        this.dataSize = 0;
    }
    ngOnInit() {
        this.nav.show();
        this.search();
    }
    search() {
        this.eventAdminService.getEventListUsingGET(this.page, this.PAGE_SIZE, this.sort == null ||
            this.sort.direction == null ||
            this.sort.active == null ||
            this.sort.direction === undefined ||
            this.sort.active === undefined ||
            this.sort.direction === '' ||
            this.sort.active === '' ?
            [this.columnToSortSymbol('createDate') + ',asc'] :
            [this.columnToSortSymbol(this.sort.active) + ',' + this.sort.direction]).subscribe((result) => {
            this.totalElements = result.data.totalElement;
            this.dataSize = result.data.events.length;
            this.dataSource.update(result.data.events);
        });
    }
    columnToSortSymbol(column) {
        switch (column) {
            case 'eventName':
                return 'eventName';
            case '"eventAliasName"':
                return 'eventAlias';
            case 'createDate':
                return 'createdDate';
        }
    }
    pageSizeChange() {
        this.page = 0;
        this.search();
    }
    onSortChange(sort) {
        this.sort = sort;
        this.search();
    }
    pageChange() {
        this.search();
    }
    addEvent() {
        this.router.navigate(['regis/event/create']);
    }
    getFirstElementOfPage() {
        let firstElement = 0;
        if (this.totalElements > 0) {
            firstElement = this.totalElements > this.PAGE_SIZE ? (this.page * this.PAGE_SIZE) + 1 : 1;
        }
        return firstElement;
    }
    getLastElementOfPage() {
        return this.totalElements > this.PAGE_SIZE ? (this.page * this.PAGE_SIZE) + this.dataSize : this.dataSize;
    }
}
class EventDataSource extends DataSource {
    constructor() {
        super(...arguments);
        this.data = new BehaviorSubject([]);
    }
    connect() {
        return this.data;
    }
    disconnect() {
    }
    update(x) {
        this.data.next(x);
    }
}
