/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./web-ex.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./web-ex.component";
import * as i8 from "@ent-regis/entregis-ts-angular/api/webExAdminController.service";
var styles_WebExComponent = [i0.styles];
var RenderType_WebExComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WebExComponent, data: {} });
export { RenderType_WebExComponent as RenderType_WebExComponent };
function View_WebExComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Event User : "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "font-weight-normal"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Client ID : "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "font-weight-normal"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h6", [["id", "web-ex-link-field-company-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Username :"])), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "input-group mb-3 mx-2 w-25"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "input", [["class", "form-control d-inline"], ["disabled", ""], ["id", "web-ex-link-field-company-input"], ["type", "text"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 16777216, null, null, 3, "button", [["class", "btn btn-sm btn-secondary"], ["id", "web-ex-link-field-company-clipboard-button"], ["name", "username"], ["ngbTooltip", "Copy URL to clipboard"], ["placement", "top"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClipboard(_v.context.$implicit.username) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 737280, null, 0, i2.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { placement: [0, "placement"], ngbTooltip: [1, "ngbTooltip"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(18, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-primary my-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.connectWebEx(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CONNECT"])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "top"; var currVal_4 = "Copy URL to clipboard"; _ck(_v, 16, 0, currVal_3, currVal_4); var currVal_7 = _co.faCopy; _ck(_v, 18, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.usertype; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.clientId; _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.username; _ck(_v, 13, 0, currVal_2); var currVal_5 = i1.ɵnov(_v, 18).title; var currVal_6 = i1.ɵnov(_v, 18).renderedIconHTML; _ck(_v, 17, 0, currVal_5, currVal_6); }); }
export function View_WebExComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container-custom mt-3 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Webex"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebExComponent_1)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listWebEx; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_WebExComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-web-ex", [], null, null, null, View_WebExComponent_0, RenderType_WebExComponent)), i1.ɵdid(1, 114688, null, 0, i7.WebExComponent, [i8.WebExAdminControllerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WebExComponentNgFactory = i1.ɵccf("app-web-ex", i7.WebExComponent, View_WebExComponent_Host_0, {}, {}, []);
export { WebExComponentNgFactory as WebExComponentNgFactory };
