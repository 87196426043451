<div id="print-section">
  <div class="container pt-4 position-relative" *ngIf="scheduleData">
    <img id="investor-schedule-print-event-logo-image" class="position-absolute" style="top: 0; right: 0;" height="80px" [src]="scheduleData.eventLogoUrl">
    <h4 id="investor-schedule-print-name-text">{{scheduleData.name}}</h4>
    <p id="investor-schedule-print-company-name-text" class="text-muted">{{scheduleData.companyName}}</p>
    <hr>
    <div class="pt-3">
      <h5 id="investor-schedule-print-event-name-text">{{scheduleData.eventName}}</h5>
      <p id="investor-schedule-print-location-text" *ngIf="scheduleData.eventType === eventTypeEnum.THAILANDFOCUS">Location : {{scheduleData.location || '-'}}</p>
      <div class="table-responsive-lg mt-3 p-3 border border-secondary">
        <table id="investor-schedule-print-table" class="table">
          <thead>
            <tr>
              <th id="investor-schedule-print-date-time-column" scope="col" style="width: 15%;">DATE TIME</th>
              <th id="investor-schedule-print-room-column" scope="col" class="text-center">ROOM</th>
              <th id="investor-schedule-print-company-column" scope="col">COMPANY</th>
              <th id="investor-schedule-print-presentation-column" scope="col" *ngIf="haveAtLeaseOnePresentation">PRESENTATION</th>
              <th id="investor-schedule-print-presenter-column" scope="col">PRESENTER</th>
              <th id="investor-schedule-print-attendees-column" scope="col">ATTENDEES</th>
              <th id="investor-schedule-print-meeting-type-column" scope="col" class="text-center" style="width: 15%;">MEETING TYPE</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let slot of scheduleData.slots; let i = index;">
              <tr>
                <td colspan="7" [id]="'investor-schedule-print-slot-date-text-' + i"><strong>{{slot.slotDate | date}}</strong></td>
              </tr>
              <ng-container *ngFor="let slotPeriod of slot.slotPeriods">
                <tr>
                  <td class="text-center" [id]="'investor-schedule-print-period-text-' + i">
                    {{slotPeriod.startTime | date:'HH:mm' : scheduleData.eventType === eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}} - {{slotPeriod.endTime | date:'HH:mm zzz' : scheduleData.eventType === eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}}
                    <span *ngIf="scheduleData.eventType === eventTypeEnum.THAILANDFOCUS"><br> (Bangkok Time)</span>
                  </td>
                  <td class="text-center" [id]="'investor-schedule-print-room-text-' + i">
                    <div *ngIf="slotPeriod.roomLink; else elseBlock" [tooltip]="slotPeriod.roomDesc">{{slotPeriod.room}} <a target="_blank" [href]="slotPeriod.roomLink">&nbsp;Click&nbsp;</a></div>
                    <ng-template #elseBlock>
                      <div [tooltip]="slotPeriod.roomDesc">{{slotPeriod.room}}</div>
                    </ng-template>
                  </td>
                  <td [id]="'investor-schedule-print-presenter-text-' + i">{{slotPeriod.presenter}}</td>
                  <td *ngIf="haveAtLeaseOnePresentation" [id]="'investor-schedule-print-presenter-link-text-' + i">
                    <a *ngIf="slotPeriod.presentationLink" target="_blank" href="{{slotPeriod.presentationLink}}">Download</a>
                  </td>
                  <td [id]="'investor-schedule-print-participant-text-' + i">
                    <div *ngFor="let participant of slotPeriod.participants">
                      <label>{{participant.name}}
                        <span class="font-italic">{{' (' + participant.jobTitle + ') '}}</span>
                      </label>
                      <br>
                    </div>
                  </td>
                  <td [id]="'investor-schedule-print-attendee-text-' + i">
                    <div *ngFor="let attendee of slotPeriod.attendees; let i = index">
                      <div>
                        <label [className]="attendee === scheduleData.name ? 
                        'bg-primary d-inline-flex px-2 py-1 rounded font-weight-bold': 'px-2'">{{attendee}}</label>
                      </div>
                    </div>
                  </td>
                  <td class="text-center" [id]="'investor-schedule-print-meeting-type-text-' + i">{{slotPeriod.meetingType | meetingType}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>