import { Component, OnInit } from '@angular/core';
import { InvestorCompanyResponse } from '@ent-regis/entregis-ts-angular';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InvestorCompanyService } from 'src/app/shared/services/investor-company.service';

@Component({
  selector: 'app-approve-investor-company-modal',
  templateUrl: './approve-investor-company-modal.component.html',
  styleUrls: ['./approve-investor-company-modal.component.scss']
})
export class ApproveInvestorCompanyModalComponent implements OnInit {

  newInvestorCompany: InvestorCompanyResponse;
  toInvestorCompany: any;

  constructor(
    public bsModalRef: BsModalRef,
    private investorCompanyService: InvestorCompanyService
  ) { }

  ngOnInit() {}

  approve() {
    this.investorCompanyService.approveInvestorCompany(this.newInvestorCompany.investorCompanyId, () => {
      this.investorCompanyService.fetchInvestorCompanies();
      this.investorCompanyService.fetchNeedApprove();
      this.bsModalRef.hide();
    });
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
