import { Injectable } from '@angular/core';
import * as qz from 'qz-tray';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

declare var qz: any;
declare const sift: any;

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  qzConnectStatus = new BehaviorSubject<QZ_TRAY_CONNECT_STATUS>('NOT_CONNECT');

  constructor() {
    qz.security.setCertificatePromise((resolve, reject) => {
      fetch(environment.baseUrl + "/public/printer/qz-tray/cert", 
      {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
      .then(function(result) {
        result.ok ? result.json().then(r => resolve(r.data)) : reject(result.text());
      })
     });

    qz.security.setSignatureAlgorithm("SHA512");
     qz.security.setSignaturePromise(function(toSign) {
      return function(resolve, reject) {
         fetch(environment.baseUrl + "/public/printer/qz-tray/signing?request=" + toSign, 
         {cache: 'no-store', headers: {'Content-Type': 'application/json'}})
            .then(function(result) {
              result.ok ? result.json().then(r => resolve(r.data)) : reject(result.text());
            })
        };
    });
  }
  

  connectQZTray(): Promise<void> {
    if (!qz.websocket.isActive()) {

      this.qzConnectStatus.next('CONNECTING');

      return qz.websocket.connect()
        .then(() => { this.qzConnectStatus.next('CONNECTED'); });
    } else {
      return Promise.resolve();
    }
  }

  getDefaultPrinterName(): Promise<string> {
    return this.connectQZTray().then(() => {
      return qz.printers.getDefault().then((data) => {
        return data;
      });
    });
  }

  print(printerName: string, zpl: string) {
    const config = qz.configs.create(printerName);
    const data = [
      zpl
    ];
    qz.print(config, data).catch((e) => { console.error(e); });
  }
}

export type QZ_TRAY_CONNECT_STATUS = 'NOT_CONNECT' | 'CONNECTING' | 'CONNECTED';
export interface Printer {
  name: string;
  status: string;
}
export type PrinterStatus = 'OK' | 'OFFLINE' | 'OTHER';
