import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // this.router.navigate(['..', 'event', 'thailand-focus-2020'], {relativeTo: this.route});
  }

}
