import { Injectable } from '@angular/core';
import { APIResult,
  InvestorCompanyAdminControllerService,
  InvestorCompanyAdminNeedApproveResponse,
  InvestorCompanyAdminResponse
} from '@ent-regis/entregis-ts-angular';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class InvestorCompanyService {

  investorCompanies = new ReplaySubject<InvestorCompanyAdminResponse[]>(1);
  needApprove = new ReplaySubject<InvestorCompanyAdminNeedApproveResponse>(1);

  constructor(
    private investorCompanyAdminService: InvestorCompanyAdminControllerService
  ) {
    this.fetchAllData();
  }

  fetchAllData() {
    this.fetchInvestorCompanies();
    this.fetchNeedApprove();
  }

  fetchInvestorCompanies() {
    this.getAllInvestorCompanies((companies) => {
      this.investorCompanies.next(companies);
    });
  }

  private getAllInvestorCompanies(completion: (companies: InvestorCompanyAdminResponse[]) => void) {
    this.investorCompanyAdminService.getAllInvestorCompaniesUsingGET().subscribe((result: APIResult) => {
      if (completion) { completion(result.data); }
    });
  }

  approveInvestorCompany(investorCompanyId: number, completion: () => void) {
    this.investorCompanyAdminService.updateIsApproveByInvestorCompanyIdUsingPOST(investorCompanyId)
    .subscribe(() => {
      if (completion) { completion(); }
    });
  }

  mergeInvestorCompany(fromInvestorCompanyId: number, toInvestorCompanyId: number, completion: () => void) {
    this.investorCompanyAdminService.mergeInvestorCompanyUsingPOST(fromInvestorCompanyId, toInvestorCompanyId)
    .subscribe(() => {
      if (completion) { completion(); }
    });
  }

  fetchNeedApprove() {
    this.getNeedApprove((needApprove) => {
      this.needApprove.next(needApprove);
    });
  }

  private getNeedApprove(completion: (needApprove: InvestorCompanyAdminNeedApproveResponse) => void) {
    this.investorCompanyAdminService.getNeedApproveUsingGET().subscribe((result: APIResult) => {
      if (completion) { completion(result.data); }
    });
  }
}
