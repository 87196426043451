import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prints',
  templateUrl: './prints.component.html',
  styleUrls: ['./prints.component.scss']
})
export class PrintsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
