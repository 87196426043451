/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/shared-components/navigator/navigator.component.ngfactory";
import * as i3 from "../../../shared/shared-components/navigator/navigator.component";
import * as i4 from "../../../shared/shared-components/event-form-info/event-form-info.component.ngfactory";
import * as i5 from "../../../shared/shared-components/event-form-info/event-form-info.component";
import * as i6 from "@ent-regis/entregis-ts-angular/api/eventAdminController.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../shared/services/form-utils.service";
import * as i9 from "./event-info.component";
import * as i10 from "@angular/router";
var styles_EventInfoComponent = [i0.styles];
var RenderType_EventInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventInfoComponent, data: {} });
export { RenderType_EventInfoComponent as RenderType_EventInfoComponent };
export function View_EventInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container-custom mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navigator", [["id", "event-navigator-component"]], null, null, null, i2.View_NavigatorComponent_0, i2.RenderType_NavigatorComponent)), i1.ɵdid(2, 114688, null, 0, i3.NavigatorComponent, [], { parentLabel: [0, "parentLabel"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-event-form-info", [["id", "event-form-info-component"]], null, [[null, "saveSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveSuccess" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_EventFormInfoComponent_0, i4.RenderType_EventFormInfoComponent)), i1.ɵdid(4, 638976, null, 0, i5.EventFormInfoComponent, [i6.EventAdminControllerService, i7.ViewportScroller, i8.FormUtilsService], null, { saveSuccess: "saveSuccess" })], function (_ck, _v) { var currVal_0 = "Event"; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_EventInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-info", [], null, null, null, View_EventInfoComponent_0, RenderType_EventInfoComponent)), i1.ɵdid(1, 114688, null, 0, i9.EventInfoComponent, [i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventInfoComponentNgFactory = i1.ɵccf("app-event-info", i9.EventInfoComponent, View_EventInfoComponent_Host_0, {}, {}, []);
export { EventInfoComponentNgFactory as EventInfoComponentNgFactory };
