<div id="print-section">
  <div class="container pt-4 position-relative" *ngIf="scheduleData">
    <img id="company-schedule-print-event-logo-image" class="position-absolute" style="top: 0; right: 0;" height="80px"
      [src]="scheduleData.eventLogoUrl">
    <h4 id="company-schedule-print-name-text">{{scheduleData.name}}</h4>
    <p id="company-schedule-print-company-name-text" class="text-muted">{{scheduleData.companyName}}</p>
    <hr>
    <div class="pt-3">
      <h5 id="company-schedule-print-event-name-text">{{scheduleData.eventName}}</h5>
      <p id="company-schedule-print-location-text" *ngIf="scheduleData.eventType === eventTypeEnum.THAILANDFOCUS">
        Location : {{scheduleData.location || '-'}}</p>
      <div class="table-responsive-lg mt-3 p-3 border border-secondary">
        <table id="company-schedule-print-table" class="table">
          <thead>
            <tr>
              <th id="company-schedule-print-date-time-column" scope="col" style="width: 15%;">DATE TIME</th>
              <th id="company-schedule-print-room-column" scope="col" class="text-center">ROOM</th>
              <th id="company-schedule-print-participant-column" scope="col">PARTICIPANTS</th>
              <th id="company-schedule-print-investors-column" scope="col">INVESTORS</th>
              <th id="company-schedule-print-meeting-type-column" scope="col" class="text-center" style="width: 15%;">
                MEETING TYPE</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let slot of scheduleData.slots; let i = index;">
              <tr>
                <td colspan="6" [id]="'company-schedule-print-slot-date-text-' + i"><strong>{{slot.slotDate |
                    date:'mediumDate': scheduleData.eventType ===
                    eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}}</strong></td>
              </tr>
              <ng-container *ngFor="let slotPeriod of slot.slotPeriods; let j = index;">
                <tr>
                  <td class="text-center" [id]="'company-schedule-print-period-text-' + i + '-' + j">
                    {{slotPeriod.startTime | date:'HH:mm': scheduleData.eventType === eventTypeEnum.THAILANDFOCUS ? 'UTC
                    +7' : ''}} - {{slotPeriod.endTime | date:'HH:mm zzz': scheduleData.eventType ===
                    eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}}
                    <span *ngIf="scheduleData.eventType === eventTypeEnum.THAILANDFOCUS"><br> (Bangkok Time)</span>
                  </td>
                  <td class="text-center" [id]="'company-schedule-print-room-text-' + i + '-' + j">
                    <div *ngIf="slotPeriod.roomLink; else elseBlock" [tooltip]="slotPeriod.roomDesc">{{slotPeriod.room}}
                      <a target="_blank" [href]="slotPeriod.roomLink">&nbsp;Click&nbsp;</a>
                    </div>
                    <ng-template #elseBlock>
                      <div [tooltip]="slotPeriod.roomDesc">{{slotPeriod.room}}</div>
                    </ng-template>
                  </td>
                  <td [id]="'company-schedule-print-participant-text-' + i + '-' + j">
                    <div *ngFor="let participant of slotPeriod.participants">
                      <label [className]="participant.name === scheduleData.name ? 
                    'bg-primary d-inline-flex px-2 py-1 rounded font-weight-bold': 'px-2'">{{participant.name}}
                        <span class="font-italic">{{' (' + participant.jobTitle + ') '}}</span></label>
                      <br>
                    </div>
                  </td>
                  <td [id]="'company-schedule-print-attendee-text-' + i + '-' + j">
                    <div *ngFor="let attendee of slotPeriod.attendees; let k = index;">
                      <div>
                        <label
                          [id]="'company-schedule-print-attendee-name-text-' + i + '-' + j + '-' + k">{{attendee.name}}
                          <span [id]="'company-schedule-print-attendee-company-name-text-' + i + '-' + j + '-' + k"
                            class="font-italic">({{attendee.companyName}})</span></label>
                      </div>
                    </div>
                  </td>
                  <td class="text-center" [id]="'company-schedule-print-meeting-type-text-' + i+ '-' + j">
                    {{slotPeriod.meetingType | meetingType}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>