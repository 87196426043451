import { EntRegisAuthService } from './ent-regis-auth.service';
import { Adal8Service } from 'adal-angular8';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private adalService: Adal8Service,
    private router: Router,
    private authService: EntRegisAuthService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.adalService.userInfo.authenticated) {
      const isAuthenticated = this.authService.isAuthenticated;
      if (typeof isAuthenticated === 'boolean') {
        if (isAuthenticated) {
          return true;
        } else {
          localStorage.setItem('comeBackTo', window.location.href);
          this.router.navigate(['login']);

          return false;
        }
      } else {
        return isAuthenticated;
      }
    } else {
      localStorage.setItem('comeBackTo', window.location.href);
      this.router.navigate(['login']);
      return false;
    }
  }
}
