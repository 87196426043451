/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./approve-investor-company-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./approve-investor-company-modal.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "../../../shared/services/investor-company.service";
var styles_ApproveInvestorCompanyModalComponent = [i0.styles];
var RenderType_ApproveInvestorCompanyModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApproveInvestorCompanyModalComponent, data: {} });
export { RenderType_ApproveInvestorCompanyModalComponent as RenderType_ApproveInvestorCompanyModalComponent };
export function View_ApproveInvestorCompanyModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "modal-body text-center"], ["id", "approve-investor-company-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "title"], ["id", "approve-investor-company-title-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["APPROVE"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "p", [["id", "approve-investor-company-content-tect"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["APPROVE "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" AS A NEW COMPANY?"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-secondary mt-1"], ["id", "approve-investor-company-cancel-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CANCEL"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary ml-2 mt-1"], ["id", "approve-investor-company-approve-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.approve() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["APPROVE"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.newInvestorCompany.companyName; _ck(_v, 7, 0, currVal_0); }); }
export function View_ApproveInvestorCompanyModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-approve-investor-company-modal", [], null, null, null, View_ApproveInvestorCompanyModalComponent_0, RenderType_ApproveInvestorCompanyModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.ApproveInvestorCompanyModalComponent, [i3.BsModalRef, i4.InvestorCompanyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApproveInvestorCompanyModalComponentNgFactory = i1.ɵccf("app-approve-investor-company-modal", i2.ApproveInvestorCompanyModalComponent, View_ApproveInvestorCompanyModalComponent_Host_0, {}, {}, []);
export { ApproveInvestorCompanyModalComponentNgFactory as ApproveInvestorCompanyModalComponentNgFactory };
