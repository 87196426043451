<div id="print-badge-modal" class="bg-set h-100 w-100 d-flex flex-column justify-content-around">
    <div class="d-flex justify-content-center">
        <div class="card" style="width: 30rem;">
            <div class="card-body text-center">
                <h2 id="print-badge-name-text" class="mt-3">{{firstName}} {{lastName}}</h2>
                <h6 id="print-badge-company-name-text" class="text-muted">{{companyName}}</h6>
                <div class="text-center mt-3">
                    <button id="print-badge-cancel-button" class="btn btn-lg btn-secondary mr-2" (click)="cancel()">Cancel</button>
                    <button id="print-badge-print-button" class="btn btn-lg btn-primary text-white px-3" (click)="print()">Print</button>
                </div>
                <p id="print-badge-error-text" class="text-danger">{{alertText}}</p>
                <span id="print-badge-attendee-type-text" class="float-left mt-2 p-1 rounded-pill" [ngStyle]="{'background-color': color}">Attendee type: {{ attendeeType | attendeeType }}</span>
            </div>
        </div>
    </div>
</div>