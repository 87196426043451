import * as tslib_1 from "tslib";
export class AuthProvider {
    constructor(apiConfig, entRegisAuthService) {
        this.apiConfig = apiConfig;
        this.entRegisAuthService = entRegisAuthService;
    }
    onAppLoaded() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!this.apiConfig.apiKeys) {
                    this.apiConfig.apiKeys = {};
                }
                this.apiConfig.apiKeys.Authorization = 'Bearer ' + this.entRegisAuthService.token;
                resolve();
            }));
        });
    }
}
